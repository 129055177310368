import axios from "axios";

export const DEBUG_MODE = false && process.env.NODE_ENV !== "production";
export const WEB_URL = DEBUG_MODE ? "http://localhost:3001" : "https://som.kg";
export const API_VERSION = 1;
export const WEB_VERSION = 1;

export const NO_AVATAR_URL = "/images/no-avatar-300x300.png";

export const GA_TRACKING_ID = "UA-125818430-1";

export const API_URL_WITHOUT_VERSION = DEBUG_MODE
  ? "http://localhost:5000"
  : "https://api.som.kg";

export function API_URL(version = API_VERSION) {
  return `${API_URL_WITHOUT_VERSION}/v${version}`;
}

export function initialize(appStore) {
  axios.interceptors.request.use(
    function (config) {
      appStore.setBusyState(true);
      if (appStore.token)
        config.headers.common.Authorization = "Basic " + appStore.token;
      return config;
    },
    function (error) {
      appStore.setBusyState(false);
      appStore.showError(error.message || error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      const { data } = response;
      appStore.setBusyState(false);
      if (data.result < 0) {
        appStore.showError(data.data.message);
        Promise.reject(data);
      }
      return data;
    },
    function (error) {
      appStore.setBusyState(false);
      appStore.showError(error.message || error);
      Promise.reject(error);
    }
  );
}
