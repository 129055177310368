import axios from "axios";
import {API_URL, API_VERSION} from "../settings";

export const daysToWord = (days, lang) => {
  if (lang === 'ky') return 'күн';
  else if (lang === 'ru'){
    if (days === 0 || days === 1) return 'день';
    else if (days >= 2 && days <= 4) return 'дня';
    else return 'дней';
  }
  return 'unknown';
};

export const checkBoolean = (s) => {
  return s === "1" || s === "true" || s === 1 || s === true;
};

export const ruAgeToWord = (age) => {
  age = age % 10;
  if (age === 1) return 'год';
  else if ([2, 3, 4].includes(age)) return 'года';
  else return 'лет';
};

export const codeTranslator = (f) => {
  let array = f.split('_');
  let result = '';
  array.map((v) => {
    result += v.substr(0, 1).toLocaleUpperCase() + v.substr(1, v.length - 1).toLocaleLowerCase();
  });
  return result;
};

export const filterPhoneNumbers = (numbers) => {
  return numbers.map(v => filterPhoneNumber(v));
};

export const filterPhoneNumber = (number) => {
  number = number || '|0|0';
  let r = {phone: "", whatsapp: false, confirmed: false, exists: false};
  if (number && number.length > 0) {
    const p = number.split('|');
    r.phone = p[0] || '';
    r.whatsapp = checkBoolean(p[1]);
    r.confirmed = checkBoolean(p[2]);
    r.exists = r.phone.length === 12;
  }
  return r;
};

export const services = {
  vip: {
    title: 'VIP',
    description: '',
    price: 150,
    style: 'dark',
    name: 'vip',
  },
  premium: {
    title: 'Премиум',
    description: '',
    price: 100,
    style: 'danger',
    name: 'premium',
  },
  exclusive: {
    title: 'Exclusive',
    description: '',
    price: 50,
    style: 'warning',
    name: 'exclusive',
  },
  free: {
    title: 'Обычная продажа',
    description: '',
    price: 0,
    style: 'secondary',
    name: 'free',
  },
};

async function loadLanguage(){
  return window.localStorage.getItem('language') || null;
}

async function saveLanguage(value){
  return !window.localStorage.setItem('language', value) || true;
}

async function clearLanguage(){
  return !window.localStorage.removeItem('language') || true;
}

async function loadCategories(){
  return JSON.parse(await window.localStorage.getItem('categories')) || [];
}

async function saveCategories(value){
  return !window.localStorage.setItem('categories', JSON.stringify(value)) || true;
}

async function clearCategories(){
  return !window.localStorage.removeItem('categories') || true;
}

async function loadPlaces(){
  return JSON.parse(await window.localStorage.getItem('places')) || [];
}

async function savePlaces(value){
  return !window.localStorage.setItem('places', JSON.stringify(value)) || true;
}

async function clearPlaces(){
  return !window.localStorage.removeItem('places') || true;
}

async function saveToken(token) {
  return !window.localStorage.setItem('token', token) || true;
}

async function loadToken() {
  return window.localStorage.getItem('token') || null;
}

async function clearToken() {
  return !window.localStorage.removeItem('token') || true;
}

async function savePlace(data) {
  return !window.localStorage.setItem('place', JSON.stringify(data)) || true;
}

async function loadPlace() {
  return JSON.parse(await window.localStorage.getItem('place')) || null;
}

async function clearPlace() {
  return !window.localStorage.removeItem('place') || true;
}

export const storage = {
  loadToken,
  loadLanguage,
  saveLanguage,
  clearToken,
  clearLanguage,
  saveToken,
  saveCategories,
  loadCategories,
  clearCategories,
  savePlaces,
  loadPlaces,
  clearPlaces,
  savePlace,
  loadPlace,
  clearPlace,
};

export const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    let value = data == null ? '' : data;
    if (value === true) value = "1";
    else if (value === false) value = "0";
    formData.append(parentKey, value);
  }
};

export const requester = {
  get: function (url, params){
    return axios.get(`${API_URL()}${url}`, {params: params});
  },
  post: function (url, data, version = API_VERSION){
    const form_data = new FormData();
    if (data) buildFormData(form_data, data);
    return axios.post(`${API_URL(version)}${url}`, form_data);
  },
  put: function (url, data) {
    return axios.put(`${API_URL()}${url}`, data);
  },
  delete: function (url, params) {
    return axios.delete(`${API_URL()}${url}`, {params: params});
  }
};
