import React from 'react';
import {withTranslation} from "react-i18next";
import {Button, Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake} from "@fortawesome/free-solid-svg-icons";
import autobind from "autobind-decorator";
import {inject, observer} from "mobx-react";
import {checkBoolean, ruAgeToWord} from "../../utils";
import moment from "moment";
import {COMPUTER_MAKES, MINIBUS_MAKES, PHONE_MAKES, TRUCK_MAKES} from "../../data";
import {action, observable} from "mobx";

@withTranslation()
class PriceQuestion extends React.Component {
  form_name = 'price';

  constructor(props) {
    super(props);

    const {defaultValue} = this.props;

    this.initialState = {
      price: (defaultValue && defaultValue.price) || '',
      currency: (defaultValue && defaultValue.currency) || 'сом',
    };

    this.state = this.initialState;
  }

  setData() {
    let data = null;
    if (!(this.state.price.length < 1 || this.state.price < 1) && this.state.price.length > 0 && this.state.price > 0) data = {
      price: this.state.price,
      currency: this.state.currency
    };
    return data;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.price !== this.state.price || prevState.currency !== this.state.currency) {
      this.props.onValidValue(this.setData());
    }
  }

  @autobind negotiableHandler() {
    this.setState({price: ''});
    this.props.nextQuestion && this.props.nextQuestion();
  }

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <div className="mt-2">
          <InputGroup>
            <FormControl
              type="number"
              size="sm"
              min={1}
              step={1}
              placeholder={t(`adQuestions.questions.${this.form_name}`)}
              aria-label={t(`adQuestions.questions.${this.form_name}`)}
              isInvalid={this.state.price.length > 0 && this.state.price < 1}
              onChange={(e) => this.setState({price: e.target.value})}
              value={this.state.price}
            />
          </InputGroup>
          <div className="mt-2 d-flex">
            <Form.Check
              checked={this.state.currency === 'сом'}
              onChange={() => this.setState({currency: 'сом'})}
              inline className="w-100"
              label="сом"
              id="addAdCurrency_1"
              name="addAdCurrency"
              type={'radio'}/>
            <Form.Check
              checked={this.state.currency === 'доллар'}
              onChange={() => this.setState({currency: 'доллар'})}
              inline className="w-100"
              label="доллар"
              id="addAdCurrency_2"
              name="addAdCurrency"
              type={'radio'}/>
          </div>
        </div>
        <Button size="sm" onClick={this.negotiableHandler}
                variant={this.state.price.length < 1 || this.state.price < 1 ? 'info' : 'light'} block
                className="mt-3">
          <FontAwesomeIcon icon={faHandshake} fixedWidth/> {t(`adQuestions.negotiable`)}
        </Button>
      </React.Fragment>
    );
  }
}

@withTranslation()
class AgeOfSummaryQuestion extends React.Component {
  form_name = 'age_of_summary';

  constructor(props) {
    super(props);

    const {defaultValue} = this.props;

    this.initialState = {
      value: defaultValue || '',
    };

    this.state = this.initialState;
  }

  validate = (e) => {
    return e && e.length > 0 && e >= 18 && e <= 100;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      if (this.validate(this.state.value)) {
        this.props.onValidValue(this.state.value);
      } else {
        this.props.onInvalidValue && this.props.onInvalidValue();
      }
    }
  }

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <div className="mt-2">
          <InputGroup size="sm">
            <FormControl
              type="number"
              min={1}
              step={1}
              placeholder={t(`adQuestions.questions.${this.form_name}`)}
              aria-label={t(`adQuestions.questions.${this.form_name}`)}
              isInvalid={this.state.value.length > 0 && (this.state.value < 18 || this.state.value > 100)}
              onChange={(e) => this.setState({value: e.target.value})}
              value={this.state.value}
            />
            {this.validate(this.state.value) && (
              <InputGroup.Append>
                <InputGroup.Text>{ruAgeToWord(this.state.value)}</InputGroup.Text>
              </InputGroup.Append>
            )}
          </InputGroup>
        </div>
      </React.Fragment>
    );
  }
}

@withTranslation()
class WageOfVacancyQuestion extends React.Component {
  form_name = 'wage_of_vacancy';

  constructor(props) {
    super(props);

    const {defaultValue} = this.props;

    this.initialState = {
      price: (defaultValue && defaultValue.price) || '',
      currency: (defaultValue && defaultValue.currency) || 'сом',
    };

    this.state = this.initialState;
  }

  setData() {
    let data = null;
    if (!(this.state.price.length < 1 || this.state.price < 1) && this.state.price.length > 0 && this.state.price > 0) data = {
      price: this.state.price,
      currency: this.state.currency
    };
    return data;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.price !== this.state.price || prevState.currency !== this.state.currency) {
      this.props.onValidValue(this.setData());
    }

    (this.state.price.length < 1 || this.state.price < 1) && this.props.nextQuestion && this.props.nextQuestion();
  }

  @autobind negotiableHandler() {
    this.setState({price: ''});
    this.props.nextQuestion && this.props.nextQuestion();
  }

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <div className="mt-2">
          <InputGroup>
            <FormControl
              type="number"
              size="sm"
              min={1}
              step={1}
              placeholder={t(`adQuestions.questions.${this.form_name}`)}
              aria-label={t(`adQuestions.questions.${this.form_name}`)}
              isInvalid={this.state.price.length > 0 && this.state.price < 1}
              onChange={(e) => this.setState({price: e.target.value})}
              value={this.state.price}
            />
          </InputGroup>
          <div className="mt-2 d-flex">
            <Form.Check
              checked={this.state.currency === 'сом'}
              onChange={() => this.setState({currency: 'сом'})}
              inline className="w-100"
              label="сом"
              id="addAdCurrency_1"
              name="addAdCurrency"
              type={'radio'}/>
            <Form.Check
              checked={this.state.currency === 'доллар'}
              onChange={() => this.setState({currency: 'доллар'})}
              inline className="w-100"
              label="доллар"
              id="addAdCurrency_2"
              name="addAdCurrency"
              type={'radio'}/>
          </div>
        </div>
        <Button size="sm" onClick={this.negotiableHandler}
                variant={this.state.price.length < 1 || this.state.price < 1 ? 'info' : 'light'} block
                className="mt-3">
          <FontAwesomeIcon icon={faHandshake} fixedWidth/> {t(`adQuestions.negotiable`)}
        </Button>
      </React.Fragment>
    );
  }
}

@withTranslation() @inject('store') @observer
class PlaceQuestion extends React.Component {
  form_name = 'place';

  @observable history = [];

  constructor(props) {
    super(props);

    this.placeStore = this.props.store.placeStore;
  }

  @action select = (v, anyway = false) => {
    if (v.children.length > 0 && !anyway) this.history.push(v);
    else this.props.onValidValue(v);
  };

  @action prev = () => {
    if (this.history.length === 0) return;
    this.history.splice(-1, 1);
  };

  render() {
    const {t} = this.props;
    const id = (this.props.defaultValue && this.props.defaultValue.id) || null;

    return (
      <Row>
        {(this.history.length > 0 && this.history[this.history.length - 1]) && (
          <Col sm="12">
            <Button size="sm" className="w-100 m-2"
                    onClick={() => this.select(this.history[this.history.length - 1], true)}
                    variant={(id === this.history[this.history.length - 1].id) ? 'primary' : 'light'}>{this.history[this.history.length - 1][`title_${t('lang')}`]}</Button>
          </Col>
        )}
        {(this.history.length === 0 ? this.placeStore.items : this.history[this.history.length - 1].children).map((value, index) => {
          const selected = id === value.id;
          return (
            <Col key={index} xs={6} lg={4}>
              <Button size="sm" className="w-100 m-2" onClick={() => this.select(value)}
                      variant={selected ? 'info' : 'light'}>
                {value[`title_${t('lang')}`]}
              </Button>
            </Col>
          );
        })}
        {(this.history.length > 0 && this.history[this.history.length - 1]) && (
          <Col sm="12">
            <Button size="sm" className="w-100 m-2" onClick={() => this.prev()} variant={'warning'}>Назад</Button>
          </Col>
        )}
      </Row>
    );
  }
}

class RoomsQuestion extends React.Component {
  form_name = 'rooms';

  render() {
    return (
      <Row>
        {[1, 2, 3, 4, 5, '6+'].map((v, key) => (
          <Col key={key} xs={4} lg={3}><Button size="sm" className="w-100 m-2"
                                               onClick={() => this.props.onValidValue(v)}
                                               variant={this.props.defaultValue === v ? 'info' : 'light'}>{v}</Button></Col>
        ))}
      </Row>
    );
  }
}

@withTranslation()
class FurnitureQuestion extends React.Component {
  form_name = 'furniture';

  render() {
    const {t} = this.props;
    const defaultValue = checkBoolean(this.props.defaultValue);

    return (
      <div className="d-flex flex-row justify-content-between">
        <Button size="sm" onClick={() => this.props.onValidValue(true)}
                disabled={defaultValue === true && this.props.defaultValue !== undefined}
                variant="info"
                className="mr-1 w-100">{t(`adQuestions.has`)}</Button>
        <Button size="sm" onClick={() => this.props.onValidValue(false)}
                disabled={defaultValue === false && this.props.defaultValue !== undefined}
                variant="info"
                className="ml-1 w-100">{t(`adQuestions.has_not`)}</Button>
      </div>
    );
  }
}

@withTranslation()
class AgencyOrOwnerQuestion extends React.Component {
  form_name = 'agency_or_owner';

  render() {
    const {t} = this.props;

    return (
      <Row>
        {[
          {name: 'agency', value: t(`adQuestions.agency`)},
          {name: 'owner', value: t(`adQuestions.owner`)},
        ].map((v, key) => (
          <Col xs={6} key={key}>
            <Button size="sm" className="w-100 m-2" onClick={() => this.props.onValidValue(v.name)}
                    variant={this.props.defaultValue === v.name ? 'info' : 'light'}>{v.value}</Button>
          </Col>
        ))}
      </Row>
    );
  }
}

class AreaHundredthsQuestion extends React.Component {
  form_name = 'area_hundredths';

  constructor(props) {
    super(props);

    const {defaultValue} = this.props;

    this.initialState = {
      value: defaultValue || '',
    };

    this.state = this.initialState;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      this.props.onValidValue(this.state.value);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="mt-2">
          <InputGroup>
            <FormControl
              type="number"
              size="sm"
              min={1}
              step={1}
              placeholder="Площадь цифры сотых"
              aria-label="Площадь цифры сотых"
              isInvalid={this.state.value.length > 0 && +this.state.value < 1}
              onChange={(e) => this.setState({value: e.target.value})}
              value={this.state.value}
            />
          </InputGroup>
        </div>
      </React.Fragment>
    );
  }
}

class AreaSquareMeterQuestion extends React.Component {
  form_name = 'area_square_meter';

  constructor(props) {
    super(props);

    const {defaultValue} = this.props;

    this.initialState = {
      value: defaultValue || '',
    };

    this.state = this.initialState;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      this.props.onValidValue(this.state.value);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="mt-2">
          <InputGroup>
            <FormControl
              type="number"
              size="sm"
              min={1}
              step={1}
              placeholder="Площадь — цифры кв.м."
              aria-label="Площадь — цифры кв.м."
              isInvalid={this.state.value.length > 0 && +this.state.value < 1}
              onChange={(e) => this.setState({value: e.target.value})}
              value={this.state.value}
            />
          </InputGroup>
        </div>
      </React.Fragment>
    );
  }
}

@withTranslation()
class GearTypeQuestion extends React.Component {
  form_name = 'gear_type';

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <Row>
          {[
            {name: 'automatic', value: t(`adQuestions.${this.form_name}.automatic`)},
            {name: 'mechanics', value: t(`adQuestions.${this.form_name}.mechanics`)},
          ].map((v, key) => (
            <Col xs={6} key={key}>
              <Button size="sm" className="w-100 m-2" onClick={() => this.props.onValidValue(v.name)}
                variant={this.props.defaultValue === v.name ? 'info' : 'light'}>{v.value}</Button>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  }
}

@withTranslation()
class ActionQuestion extends React.Component {
  form_name = 'action';

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <Row>
          {[
            {name: 'buy', value: t(`adQuestions.${this.form_name}.buy`)},
            {name: 'sell', value: t(`adQuestions.${this.form_name}.sell`)},
            {name: 'rent', value: t(`adQuestions.${this.form_name}.rent`)},
          ].map((v, key) => (
            <Col xs={6} key={key}>
              <Button size="sm" className="w-100 m-2" onClick={() => this.props.onValidValue(v.name)} variant={this.props.defaultValue === v.name ? 'info' : 'light'}>{v.value}</Button>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  }
}

@withTranslation()
class FuelQuestion extends React.Component {
  form_name = 'fuel';

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <Row>
          {[
            {name: 'petrol', value: t(`adQuestions.${this.form_name}.petrol`)},
            {name: 'diesel', value: t(`adQuestions.${this.form_name}.diesel`)},
            {name: 'gas', value: t(`adQuestions.${this.form_name}.gas`)},
            {name: 'petrol+gas', value: t(`adQuestions.${this.form_name}.petrol+gas`)},
          ].map((v, key) => (
            <Col xs={6} key={key}>
              <Button size="sm" className="w-100 m-2" onClick={() => this.props.onValidValue(v.name)}
                      variant={this.props.defaultValue === v.name ? 'info' : 'light'}>{v.value}</Button>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  }
}

class YearOfIssueQuestion extends React.Component {
  form_name = 'year_of_issue';

  constructor(props) {
    super(props);

    this.year = moment().year();
  }

  render() {
    return (
      <React.Fragment>
        <Form.Control as="select" onChange={v => this.props.onValidValue(v.target.value)}>
          <option disabled={true} selected={!this.props.defaultValue}>Выберите</option>
          {Array.from(Array(40).keys()).map((v) => (
            <option value={this.year - v} key={v} selected={this.props.defaultValue === (this.year - v)}>{this.year - v}</option>
          ))}
        </Form.Control>
      </React.Fragment>
    );
  }
}

@withTranslation()
class MinibusMakeQuestion extends React.Component {
  form_name = 'minibus_make';

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <Form.Control as="select" onChange={v => this.props.onValidValue(v.target.value)}>
          <option disabled={true} selected={!this.props.defaultValue}>Выберите</option>
          {MINIBUS_MAKES.map((v, key) => (
            <option value={v} key={key} selected={this.props.defaultValue === v}>{v || t(`adQuestions.${this.form_name}.another`)}</option>
          ))}
        </Form.Control>
      </React.Fragment>
    );
  }
}

@withTranslation()
class TruckMakeQuestion extends React.Component {
  form_name = 'truck_make';

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <Form.Control as="select" onChange={v => this.props.onValidValue(v.target.value)}>
          <option disabled={true} selected={!this.props.defaultValue}>Выберите</option>
          {TRUCK_MAKES.map((v, key) => (
            <option value={v} key={key} selected={this.props.defaultValue === v}>{v || t(`adQuestions.${this.form_name}.another`)}</option>
          ))}
        </Form.Control>
      </React.Fragment>
    );
  }
}

@withTranslation()
class StateQuestion extends React.Component {
  form_name = 'state';

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <Row>
          {[
            {name: 'new', value: t(`adQuestions.${this.form_name}.new`)},
            {name: 'used', value: t(`adQuestions.${this.form_name}.used`)},
          ].map((v, key) => (
            <Col xs={6} key={key}>
              <Button size="sm" className="w-100 m-2" onClick={() => this.props.onValidValue(v.name)} variant={this.props.defaultValue === v.name ? 'info' : 'light'}>{v.value}</Button>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  }
}

@withTranslation()
class PhoneMakeQuestion extends React.Component {
  form_name = 'phone_make';

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <Form.Control as="select" onChange={v => this.props.onValidValue(v.target.value)}>
          <option disabled={true} selected={!this.props.defaultValue}>Выберите</option>
          {PHONE_MAKES.map((v, key) => (
            <option value={v} key={key} selected={this.props.defaultValue === v}>{v || t(`adQuestions.${this.form_name}.another`)}</option>
          ))}
        </Form.Control>
      </React.Fragment>
    );
  }
}

@withTranslation()
class ComputerMakeQuestion extends React.Component {
  form_name = 'computer_make';

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <Form.Control as="select" onChange={v => this.props.onValidValue(v.target.value)}>
          <option disabled={true} selected={!this.props.defaultValue}>Выберите</option>
          {COMPUTER_MAKES.map((v, key) => (
            <option value={v} key={key} selected={this.props.defaultValue === v}>{v || t(`adQuestions.${this.form_name}.another`)}</option>
          ))}
        </Form.Control>
      </React.Fragment>
    );
  }
}

export default {
  PriceQuestion,
  PlaceQuestion,
  RoomsQuestion,
  FurnitureQuestion,
  AgencyOrOwnerQuestion,
  WageOfVacancyQuestion,
  AgeOfSummaryQuestion,
  AreaHundredthsQuestion,
  AreaSquareMeterQuestion,
  GearTypeQuestion,
  FuelQuestion,
  ActionQuestion,
  StateQuestion,
  YearOfIssueQuestion,
  MinibusMakeQuestion,
  TruckMakeQuestion,
  PhoneMakeQuestion,
  ComputerMakeQuestion,
};
