import {action, computed, observable} from "mobx";
import {checkBoolean, filterPhoneNumber} from "../utils";
import BaseModel from "./BaseModel";

class User extends BaseModel {
  @observable id = 0;
  @observable provider = null;
  @observable provider_id = 0;
  @observable email = null;
  @observable full_name = null;
  @observable bio = null;
  @observable phone_1 = null;
  @observable phone_2 = null;
  @observable phone_3 = null;
  @observable picture = null;
  @observable last_action = null;
  @observable balance = 0;
  @observable abilities = [];
  @observable language = [];
  @observable place = null;
  @observable ads_count = 0;
  @observable is_agent = false;
  @observable created_at = null;
  @observable updated_at = null;
  @observable favorites = [];

  constructor(props) {
    super(props);

    this.is_agent = checkBoolean(props['is_agent']);

    this.phone_1 = filterPhoneNumber(props['phone_1']);
    this.phone_2 = filterPhoneNumber(props['phone_2']);
    this.phone_3 = filterPhoneNumber(props['phone_3']);
  }

  @computed get phones() {
    return [this.phone_1, this.phone_2, this.phone_3];
  }

  @action setBalance(b) {
    this.balance = b;
  }

  @action setIsAgent(n = false) {
    this.is_agent = checkBoolean(n);
  }

  @action setPhones(p) {
    this.phone_1 = p[0];
    this.phone_2 = p[1];
    this.phone_3 = p[2];
  }

  @action setPhoneByIndex(index, phone) {
    this[`phone_${index + 1}`] = phone;
  }
}

export default User;
