import {observable, action} from "mobx";
import {requester} from "../utils";
import Ad from "../models/Ad";

export default class AdStore {
  @observable addAdCategory = 0;
  @observable addAdForms = ['place'];
  @observable addAdDescription = "";
  @observable addAdContacts = observable.array([]);
  @observable addAdAdditionals = {};
  @observable addAdCurrentQuestion = -1;
  @observable addAdCurrentShow = 'place';
  @observable addAdImages = [];
  @observable addAdTitle = '';
  @observable filter_is_disabled = true;

  @observable my_items = {
    items: {
      all: [],
      pending: [],
      rejected: [],
    },
    counts: {
      all: 0,
      pending: 0,
    },
  };
  @observable fetching_my_items = false;

  constructor(props) {
    this._rootStore = props;

    this.filter_is_disabled = (localStorage.getItem('ad_filter_is_disabled') || 'true') === 'true';
  }

  @action setAddAdImages(items) {
    this.addAdImages = items;
  }

  @action setFetchingMyItems(s) {
    this.fetching_my_items = s;
  }

  @action toggleFilter() {
    this.filter_is_disabled = !this.filter_is_disabled;
    localStorage.setItem('ad_filter_is_disabled', this.filter_is_disabled ? 'true' : 'false');
  }

  @action setAddAdContacts(c) {
    this.addAdContacts = c;
  }

  @action clearAddAdForm() {
    this.addAdForms = ['place'];
    this.addAdCategory = 0;
    this.addAdDescription = '';
    this.addAdContacts = [];
    this.addAdAdditionals = {};
    this.addAdCurrentQuestion = -1;
    this.addAdCurrentShow = 'place';
    this.addAdImages = [];
    this.addAdTitle = '';
  }

  @action setAddAdTitle(t) {
    this.addAdTitle = t;
  }

  @action setAddAdAdditionals(name, value) {
    this.addAdAdditionals[name] = value;
  }

  @action setAddAdCurrentShow(s) {
    this.addAdCurrentShow = s;
  }

  @action setAddAdCategory(c) {
    this.addAdCategory = c;
  }

  @action setAddAdCurrentQuestion(q) {
    this.addAdCurrentQuestion = q;
  }

  @action setAddAdForms(c) {
    this.addAdForms = c;
  }

  @action setAddAdDescription(d) {
    this.addAdDescription = d;
  }

  @action setMyItems(items) {
    items['items'] = {
      all: items.items.all.map(v => new Ad(v)),
      sold: items.items.sold.map(v => new Ad(v)),
      published: items.items.published.map(v => new Ad(v)),
      ...items['items'],
    };
    if ('rejected' in items.items) items.items.rejected = items.items.rejected.map(v => new Ad(v));
    if ('pending' in items.items) items.items.pending = items.items.pending.map(v => new Ad(v));
    this.my_items = items;
  }

  @action appendMyItems(items) {
    items['items'].all = [...this.my_items.items.all, ...items.items.all.map(v => new Ad(v))];
    items['items'].sold = [...this.my_items.items.sold, ...items.items.sold.map(v => new Ad(v))];
    items['items'].published = [...this.my_items.items.published, ...items.items.published.map(v => new Ad(v))];
    if ('rejected' in items.items) items.items.rejected = [...this.my_items.items.rejected, ...items.items.rejected.map(v => new Ad(v))];
    if ('pending' in items.items) items.items.pending = [...this.my_items.items.pending, ...items.items.pending.map(v => new Ad(v))];
    this.my_items = items;
  }

  async fetchMyItems(id = 0, page = 1) {
    if (page === 1) this.setFetchingMyItems(true);
    try {
      const data = await requester.get('/ad/get_items_by_author_id', {
        id,
        page,
      });
      if (data.status === 'success') {
        if (page === 1) this.setMyItems(data.data);
        else this.appendMyItems(data.data);
      }
    } finally {
      if (page === 1) this.setFetchingMyItems(false);
    }
  }

  async fetchByCategory(id, page, limit = 30, _filters = {}) {
    const filters = {
      place: this._rootStore.place ? this._rootStore.place.id : null,
      ..._filters,
    };
    const data = await requester.get('/ad/get_by_category', {
      id,
      page,
      limit,
      filters: this.filter_is_disabled ? {} : filters,
    });
    if (data.status === 'success') return data.data;
    else throw new Error(data.status);
  }

  async fetchById(id) {
    const data = await requester.get('/ad/get_by_id', {id});
    if (data.status === 'success') return new Ad(data.data);
    else throw new Error(data.status);
  }

  async deleteById(id) {
    const data = await requester.post('/ad/delete', {id});
    if (data.status === 'success') return data.data;
    else throw new Error(data.status);
  }

  async rejectById(id) {
    const data = await requester.post('/ad/reject', {id});
    if (data.status === 'success') return data.data;
    else throw new Error(data.status);
  }

  async updateById(id, params) {
    const data = await requester.post('/ad/update', {id, ...params});
    if (data.status === 'success') return data.data;
    else throw new Error(data.status);
  }
}
