import React, { Component } from 'react';
import Loading from "./Loading";
import {inject} from 'mobx-react';
import Error from './Error';

@inject('store')
class LazyRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      showLoader: false
    };
    this.store = this.props.store.appStore;
    this.load = this.load.bind(this);
  }

  componentWillMount(){
    this.load();
  }

  load(){
    if (this.state.status === 'loading') return;
    this.setState({status: 'loading'});
    this.store.setBusyState(true);
  }

  componentDidMount() {
    this.props.component.then((module) => {
      this.component = module.default;
      this.setState({status: 'success'});
      this.store.setBusyState(false);
    });
  }

  render() {
    const {status} = this.state;
    const {loading} = this.props;
    if (status === 'success'){
      return <this.component {...this.props} />
    } else if (status === 'error'){
      if (this.props.error) return <this.props.error />;
      else return <Error />;
    } else {
      return (loading ? <loading /> : <Loading title="Страница грузится..."/>);
    }
  }
}

export default LazyRoute;
