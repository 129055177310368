import React, {Component} from 'react';
import {Modal, Button, Form, Row, Col, ListGroup, FormControl, InputGroup, Alert} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import {inject} from 'mobx-react';
import {daysToWord, requester, services} from "../utils";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {withRouter} from 'react-router';
import {additionalQuestionItemRenderer} from "../utils/generators";

@withRouter @inject('store')
class AddAdPreview extends Component {
  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
    this.store = this.props.store.appStore;

    this.initialState = {
      sending: false
    };

    this.state = this.initialState;
    this.send = this.send.bind(this);
  }

  async send() {
    const store = this.adStore;
    if (this.state.sending) return;

    let params = {
      category: store.addAdCategory,
      description: store.addAdDescription,
      additionals: {
        place: {
          ...store.addAdAdditionals.place,
          children: [],
        },
      },
      images: store.addAdImages,
      title: store.addAdTitle,
    };

    if (store.addAdContacts && this.store.is_agent) {
      params['phone_1'] = store.addAdContacts[0].value;
      params['phone_2'] = store.addAdContacts[1].value;
      params['phone_3'] = store.addAdContacts[2].value;
      params['whatsapp_1'] = store.addAdContacts[0].whatsapp ? 'true' : 'false';
      params['whatsapp_2'] = store.addAdContacts[1].whatsapp ? 'true' : 'false';
      params['whatsapp_3'] = store.addAdContacts[2].whatsapp ? 'true' : 'false';
    }

    this.setState({sending: true});
    requester.post('/ad/create', params).then(result => {
      if (result.status === 'success') {
        if (this.props.location.pathname === '/profile' || (this.store.user && this.props.location.pathname === `/users/view/${this.store.user.id}`)) this.adStore.fetchMyItems();
        this.store.showSuccess('Успешно отправлено!');

        // TODO: платный услугалар иштеп баштаганда ондош керек

        //this.props.nextQuestion();
        this.store.setShowAddAdModal(false);
        setTimeout(() => {
          this.adStore.clearAddAdForm();
        }, 500);

      } else this.store.showError('Error!');
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      this.setState({sending: false});
    });
  }

  render() {
    if (this.props.show === false) return null;

    const {t} = this.props;
    const data = this.adStore.addAdForms.map(value => additionalQuestionItemRenderer(value, this.adStore.addAdAdditionals[value], this.props.store));

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4 mb-3">{t("_.Preliminary")}</div>
          <ListGroup variant="flush">
            {data.map((value, index) => <ListGroup.Item key={index}>{value}</ListGroup.Item>)}
          </ListGroup>
          <div className="text-muted text-center mt-3">{t("_.alright")}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" className="w-100" variant="secondary" disabled={this.state.sending}
                  onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.back")}
          </Button>
          <Button size="sm" className="w-100" variant="primary" disabled={this.state.sending} onClick={this.send}>
            {t("buttonLabel.addAdModal.send")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
class AddAdDescription extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      description: '',
      textInputInvalid: false
    };

    this.state = this.initialState;
    this.adStore = this.props.store.adStore;

    this.descriptionChangeHandler = this.descriptionChangeHandler.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
  }

  descriptionChangeHandler(e) {
    let textInputInvalid = false;
    if (e.target.value.length > 1300) textInputInvalid = true;
    this.setState({
      description: !textInputInvalid ? e.target.value : this.state.description,
      textInputInvalid
    });
    if (textInputInvalid) return;
  }

  nextQuestion() {
    if (this.state.textInputInvalid) return;
    this.adStore.setAddAdDescription(this.state.description);
    this.props.nextQuestion();
  }

  render() {
    if (this.props.show === false) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4 d-flex justify-content-between align-items-center">
            <div>{t("_.Description")}</div>
            <div className={`text-${this.state.description.length >= 1300 ? 'danger' : 'muted'} h5`}>{this.state.description.length}/1300</div>
          </div>
          <Form.Group>
            <Form.Control isInvalid={this.state.textInputInvalid} value={this.state.description}
                          onChange={this.descriptionChangeHandler} as="textarea" rows="4"/>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button size="sm" disabled={this.state.textInputInvalid} variant="primary" onClick={this.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
class AddAdServices extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      service: services.free,
      days: 1,
    };

    this.state = this.initialState;
    this.adStore = this.props.store.adStore;
    this.store = this.props.store.appStore;

    this.serviceChangeHandler = this.serviceChangeHandler.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
  }

  serviceChangeHandler(service) {
    this.setState({service});
  }

  nextQuestion() {
    this.adStore.setAddAdService(this.state.service, this.state.days);
    this.props.nextQuestion();
  }

  button(label, name, variant) {
    return <Button onClick={() => this.serviceChangeHandler(services[name])}
                   variant={this.state.service.name === name ? 'light' : variant}>{label}</Button>;
  }

  cancel() {
    this.store.setShowAddAdModal(false);
    setTimeout(() => {
      this.adStore.clearAddAdForm();
    }, 500);
  }

  render() {
    if (this.props.show === false) return null;

    const {t} = this.props;
    const {service, days} = this.state;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">Выберите услугу</div>
          <div className={'d-flex justify-content-between'}>
            {Object.keys(services).map(name => this.button(services[name].title, name, services[name].style))}
          </div>
          <div className={'mt-2'}>
            <div>Вы выбрали услугу: <strong>{service.title}</strong></div>
            <div>Плата за 1 день: <strong>{service.price} сом</strong></div>
            <Alert className={'mt-2'} variant={service.style}>{days} {daysToWord(days, 'ru')} (включая сегодня)
              - <strong>{service.price * days} сом</strong></Alert>
            {service.price > 0 && (
              <div className={'p-3'}>
                <InputRange
                  maxValue={30}
                  minValue={1}
                  value={days}
                  onChange={value => this.setState({days: value})}/>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.cancel.bind(this)}>
            {'Отмена'}
          </Button>
          <Button size="sm" variant="primary" onClick={this.cancel.bind(this)}>
            {'Подключить'}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
class AddAdPhotos extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      files: [],
      filesCount: 0,
    };

    this.state = this.initialState;
    this.store = this.props.store.adStore;

    this.photoChangeHandler = this.photoChangeHandler.bind(this);
    this.openFilePickerHandler = this.openFilePickerHandler.bind(this);
    this.removePhotoByIndexHandler = this.removePhotoByIndexHandler.bind(this);
    this.readerOnload = this.readerOnload.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
  }

  readerOnload(e, file, count) {
    if (count <= 10) {
      this.setState(prevState => {
        return {
          ...prevState,
          files: [...prevState.files, {
            file,
            url: e.target.result,
          }],
          filesCount: count,
        };
      });
    }
  }

  photoChangeHandler(e) {
    let ofiles = e.target.files;
    let count = this.state.filesCount;
    for (let i = 0; i < ofiles.length; i++) {
      if (
        ofiles[i].type === 'image/gif' ||
        ofiles[i].type === 'image/jpeg' ||
        ofiles[i].type === 'image/jpg' ||
        ofiles[i].type === 'image/png'
      ) {
        const reader = new FileReader();
        reader.readAsDataURL(ofiles[i]);
        count++;
        reader.onload = (e) => this.readerOnload(e, ofiles[i], count);
      }
    }
  }

  nextQuestion() {
    this.store.setAddAdImages(this.state.files.map(v => v.file));
    this.props.nextQuestion();
  }

  openFilePickerHandler() {
    if (this.state.filesCount < 10) this.refs.fileUploader.click();
  }

  removePhotoByIndexHandler(index) {
    let photos = [...this.state.files];
    photos.splice(index, 1);
    this.setState(prevState => {
      return {
        ...prevState,
        files: photos,
        filesCount: photos.length
      };
    });
  }

  render() {
    if (this.props.show === false) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t("_.imge")} ({this.state.filesCount} / 10)</div>
          <Button size="sm" variant="info" disabled={this.state.filesCount >= 10} block
            onClick={this.openFilePickerHandler}>{t("_.Select")}</Button>
          <Form.Control ref="fileUploader" hidden accept="image/*" multiple onChange={this.photoChangeHandler}
                        type="file"/>
          <div className="mt-2">
            {this.state.files.map((value, index) => {
              return (
                <span onClick={() => this.removePhotoByIndexHandler(index)} className="m-2" style={{
                  display: 'inline-block',
                  width: '130px',
                  height: '100px',
                  backgroundImage: `url(${value.url})`,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover'
                }} key={index}/>
              );
            })}
          </div>
          {this.state.files.length > 0 && <div className="text-muted">Нажмите на фото, чтобы удалить</div>}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary"
                  onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button size="sm" variant="primary" onClick={this.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

function PhoneRenderer(props) {
  return (
    <div>
      <Row className="d-flex align-items-center">
        <Col xs={6} lg={8} sm={7} xl={8} md={8}>
          <InputMask mask="+\9\96 999 999 999" maskChar="" value={props.value} onChange={props.onChange}>
            {(inputProps) => (
              <InputGroup size="sm" className="mb-3">
                <FormControl
                  {...inputProps}
                  disabled={props.disabled}
                  name={`phone_${props.code}`}
                  placeholder={`Телефон номер ${props.code}`}
                  aria-label={`Телефон номер ${props.code}`}
                />
              </InputGroup>
            )}
          </InputMask>
        </Col>
        <Col className="pl-0" xs={6} lg={4} sm={5} xl={4} md={4}>
          <Form.Group controlId={`formBasicChecbox${props.code}`}>
            <Form.Check id={`whatsapp_${props.code}_id`} name={`whatsapp_${props.code}`}
                        onChange={props.onChangeWhatsapp} checked={props.checked} type="checkbox"
                        label={props.t('form.profile.contacts.whatsapp')}/>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}

@inject('store')
class AddAdContacts extends Component {
  constructor(props) {
    super(props);

    this.phones = this.props.userContacts;

    this.initialState = {
      phone_1: '',
      phone_2: '',
      phone_3: '',
      whatsapp_1: false,
      whatsapp_2: false,
      whatsapp_3: false,
      can_next: false,
    };

    this.state = this.initialState;
    this.adStore = this.props.store.adStore;

    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeWhatsapp = this.onChangeWhatsapp.bind(this);
    this.useUserContacts = this.useUserContacts.bind(this);
    this.nextQuestionHandler = this.nextQuestionHandler.bind(this);
  }

  useUserContacts() {
    this.setState({
      phone_1: this.phones[0].phone,
      phone_2: this.phones[1].phone,
      phone_3: this.phones[2].phone,
      whatsapp_1: this.phones[0].whatsapp,
      whatsapp_2: this.phones[1].whatsapp,
      whatsapp_3: this.phones[2].whatsapp,
    });
  }

  onChangePhone(e) {
    this.setState({
      [e.target.name]: e.target.value.replace('+', '').replace(/ /g, ''),
    });
  }

  onChangeWhatsapp(e) {
    this.setState({
      [e.target.name]: e.target.checked
    });
  }

  nextQuestionHandler() {
    this.props.nextQuestion();
    this.adStore.setAddAdContacts([
      {value: this.state.phone_1, whatsapp: this.state.whatsapp_1},
      {value: this.state.phone_2, whatsapp: this.state.whatsapp_2},
      {value: this.state.phone_3, whatsapp: this.state.whatsapp_3}
    ]);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.phone_1 !== this.state.phone_1 ||
      prevState.phone_2 !== this.state.phone_2 ||
      prevState.phone_3 !== this.state.phone_3
    ) {
      this.setState({
        can_next: (
          this.state.phone_1.length === 12 ||
          this.state.phone_2.length === 12 ||
          this.state.phone_3.length === 12
        ),
      });
    }
  }

  render() {
    if (this.props.show === false) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t("_.Contact")}</div>
          <PhoneRenderer onChangeWhatsapp={this.onChangeWhatsapp} checked={this.state.whatsapp_1} t={t} code={1}
                         value={this.state.phone_1} onChange={this.onChangePhone}/>
          <PhoneRenderer onChangeWhatsapp={this.onChangeWhatsapp} checked={this.state.whatsapp_2} t={t} code={2}
                         value={this.state.phone_2} onChange={this.onChangePhone}/>
          <PhoneRenderer onChangeWhatsapp={this.onChangeWhatsapp} checked={this.state.whatsapp_3} t={t} code={3}
                         value={this.state.phone_3} onChange={this.onChangePhone}/>
          {(this.phones[0].exists || this.phones[1].exists || this.phones[2].exists) &&
          <Button size="sm" block variant="info" onClick={this.useUserContacts}>Используй мои контактные
            данные</Button>}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button size="sm" disabled={!this.state.can_next} variant="primary" onClick={this.nextQuestionHandler}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
class AddAdDefaultQuestionsComponent extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      userContacts: this.props.userContacts
    };

    this.appStore = this.props.store.appStore;

    this.state = this.initialState;
  }

  render() {
    const {t} = this.props;

    return (
      <React.Fragment>
        <AddAdPhotos
          show={this.props.question === 0}
          t={t}
          nextQuestion={this.props.nextQuestion}
          prevQuestion={this.props.prevQuestion}/>
        <AddAdDescription
          show={this.props.question === 1}
          t={t}
          nextQuestion={this.props.nextQuestion}
          prevQuestion={this.props.prevQuestion}/>
        <AddAdContacts
          userContacts={this.state.userContacts}
          show={this.props.question === 2 && this.appStore.is_agent}
          t={t}
          nextQuestion={this.props.nextQuestion}
          prevQuestion={this.props.prevQuestion}/>
        <AddAdPreview
          show={(this.props.question + (this.appStore.is_agent ? 0 : 1)) === 3}
          t={t}
          nextQuestion={this.props.nextQuestion}
          prevQuestion={this.props.prevQuestion}/>
        {/*<AddAdServices*/}
        {/*  show={(this.props.question + (this.appStore.is_agent ? 0 : 1)) === 4}*/}
        {/*  t={t}*/}
        {/*  nextQuestion={this.props.nextQuestion}*/}
        {/*  prevQuestion={this.props.prevQuestion}/>*/}
      </React.Fragment>
    );
  }
}

export default AddAdDefaultQuestionsComponent;
