import React from 'react';
import {Modal, Button} from "react-bootstrap";
import {observer} from "mobx-react";
import {action, observable} from "mobx";

export const CODE = 3;
export const SKIPPED = (JSON.parse(localStorage.getItem(`skipped_download_app`)) || 0) >= CODE;

@observer
class DownloadAppModalComponent extends React.Component {
  @observable skipped = true;

  constructor(props) {
    super(props);

    this.skipped = SKIPPED;
  }

  @action skip = () => {
    localStorage.setItem('skipped_download_app', JSON.stringify(CODE));
    this.skipped = true;
  };

  render() {
    const {store, t} = this.props;

    return (
      <Modal backdrop="static" centered show={store.show_download_app && !this.skipped} onHide={() => this.skip()}>
        <Modal.Body style={{backgroundColor: '#F6F6F6', borderRadius: 20, textAlign: 'center'}}>
          <img style={{width: '70%'}}
               src={'https://www.pngkey.com/png/detail/75-758696_transparent-library-robot-winner-png-stickpng-android-hello.png'}/>
          <h5>{t('download_app.android.title')}</h5>
          <div className={'text-muted my-2'}>{t('download_app.android.content')}</div>
          {t('lang') === 'ky' ? (
            <a onClick={() => this.skip()} href='https://play.google.com/store/apps/details?id=kg.vista.som1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img style={{width: '40%'}} alt="Google Play'ден алыңыз"
                   src='https://play.google.com/intl/en_us/badges/static/images/badges/ky_badge_web_generic.png'/>
            </a>
          ) : (
            <a onClick={() => this.skip()} href='https://play.google.com/store/apps/details?id=kg.vista.som1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img style={{width: '40%'}} alt='Доступно в Google Play'
                   src='https://play.google.com/intl/en_us/badges/static/images/badges/ru_badge_web_generic.png'/>
            </a>
          )}
          <br />
          <Button size={'sm'} className={'mt-3'} variant="light" onClick={() => this.skip()}>
            {t('download_app.close')}
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DownloadAppModalComponent;
