import React from 'react';
import {inject} from "mobx-react";
import {Modal, Button, FormControl, InputGroup} from "react-bootstrap";
import {requester} from "../../utils";
import Loading from "../../ui/Loading";

@inject('store')
class ConfirmPhoneNumber extends React.Component {
  constructor(props) {
    super(props);

    this.sendCode = this.sendCode.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.checkCode = this.checkCode.bind(this);
    this.contentRenderer = this.contentRenderer.bind(this);
    this.resetState = this.resetState.bind(this);
    this.hide = this.hide.bind(this);
    this.confirmed = this.confirmed.bind(this);

    this.initial_state = {
      code: "",
      checking_phone: false,
      checking_code: false,
      status: "",
      error: "",
    };
    this.state = this.initial_state;

    this.store = this.props.store;
    this.appStore = this.store.appStore;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.phone !== this.props.phone && this.props.phone) this.sendCode();
  }

  resetState() {
    this.setState(this.initial_state);
  }

  confirmed() {
    const {phone} = this.props;
    this.appStore.user.setPhoneByIndex(phone.code - 1, {
      phone: phone.phone.phone,
      whatsapp: phone.phone.whatsapp,
      confirmed: true,
      exists: true,
    });
    this.hide();
  }

  async sendCode() {
    if (this.state.checking_phone) return;
    this.setState({checking_phone: true});
    try {
      const {status} = await requester.post('/user/send_confirm_code', {
        code: this.props.phone.code,
        phone: this.props.phone.phone.phone,
        whatsapp: this.props.phone.phone.whatsapp,
      });
      if (status === "already_confirmed") {
        this.appStore.showWarning('Уже подтверждено!');
        this.confirmed();
      } else {
        this.setState({status});
      }
    } catch (e) {
      this.setState({status: ""});
    } finally {
      this.setState({checking_phone: false});
    }
  }

  async checkCode() {
    if (this.state.code.length !== 4 || this.state.checking_code) return;
    this.setState({checking_code: true});
    try {
      const data = await requester.post('/user/check_confirm_code', {
        phone: this.props.phone.code,
        code: this.state.code,
      });
      if (data.status === "success") {
        this.appStore.showSuccess('Успешно подтверждено!');
        this.confirmed();
      } else if (data.status === "incorrect_code") {
        this.setState({
          error: `Неверный код! Оставшиеся попытки: ${data.data.attempts}`,
          code: "",
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({checking_code: false});
    }
  }

  onCodeChange(e) {
    const code = e.target.value;
    if (code.length > 4) return;
    this.setState({code});
  }

  contentRenderer() {
    if (this.state.checking_phone || this.state.status === "") return <Loading/>;

    return (
      <React.Fragment>
        <p>Мы отправили код подтверждения на ваш номер телефона, и вы должны ввести код ниже</p>
        {this.state.error && <p className={'text-danger'}>{this.state.error}</p>}
        <InputGroup size="sm" className="mb-3">
          <FormControl
            disabled={this.state.checking_code}
            value={this.state.code}
            type={'number'}
            onChange={this.onCodeChange}
            placeholder={`Код подтверждения`}
            aria-label={`Код подтверждения`}
          />
        </InputGroup>
        <Button onClick={this.checkCode} disabled={this.state.code.length !== 4 || this.state.checking_code} variant={'primary'} size={'sm'} block>Отправить код</Button>
      </React.Fragment>
    );
  }

  hide() {
    this.props.onHide();
    this.resetState();
  }

  render() {
    const {phone, onHide, ...rest} = this.props;

    return (
      <Modal show={phone} onHide={this.hide} {...rest}>
        <Modal.Header closeButton>
          <Modal.Title>Подтверждение номера телефона</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className={'text-center'}>+{phone && phone.phone.phone}</h4>
          {this.contentRenderer()}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ConfirmPhoneNumber;
