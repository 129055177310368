import {action} from "mobx";
import {requester} from "../utils";

export default class AdminStore {
  @action addOrUpdate(item){
    let updated = false;

    if (this.viewPageStatus === 'success' && this.viewPageItem['id'] === item.id) this.viewPageItem = item;

    this.myItems = this.myItems.map(v => {
      if (v.id === item.id) return item;
      else return v;
    });

    this.items = this.items.map(v => {
      if (v.id === item.id){
        updated = true;
        return item;
      } else return v;
    });

    if (!updated) this.items.push(item);
  }

  async fetchOrGetItemById(id){
    let item = null;
    this.items.map(v => {
      if (v.id === id) item = v;
    });
    if (item === null) return await this.fetchItemById(id);
    else {
      this.fetchItemById(id);
      return item;
    }
  }

  async fetchItemById(id){
    const data = await requester.get('/ad/get_by_id', {id});
    if (data.status === 'success'){
      this.addOrUpdate(data.data);
      return data.data;
    }
    return null;
  }
}
