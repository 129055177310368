import {observable, computed, action} from "mobx";
import {requester} from '../utils';
import {WEB_VERSION} from "../settings";
import User from "../models/User";

export default class AppStore {
  i18n = null;
  storage = null;

  @observable token = null;
  @observable authenticated = false;
  @observable authenticating = true;
  @observable busy_count = 0;
  @observable language = 'ru';
  @observable device = 'desktop';
  @observable branding = null;
  @observable messages = observable.array([]);
  @observable showPlaceModal = false;
  @observable showAddAdModal = false;
  @observable place = null;
  @observable placeModalForFilter = false;

  @observable user = null;

  @observable transactions = observable.array([]);
  @observable transactions_fetching = false;
  @observable transactions_page = 1;
  @observable route_key = null;
  @observable show_download_app = true;

  @observable favorites = observable.array([]);

  @action setRouteKey = (key) => this.route_key = key;

  @computed get is_busy() {
    return this.busy_count > 0;
  }

  @computed get is_agent() {
    return this.authenticated && this.user.is_agent;
  }

  @computed get transactions_count() {
    return (this.transactions && this.transactions.count) || 0;
  }

  @computed get has_phone() {
    if (!this.authenticated || !this.user) return false;
    const phones = this.user.phones;
    return phones[0].exists || phones[1].exists || phones[2].exists || this.user.provider === 'phone';
  }

  @action setValue = (a, b) => this[a] = b;

  @computed get is_admin() {
    return this.authenticated && this.user.abilities.length > 0;
  }

  @action setTransactionsPage(l) {
    this.transactions_page = l;
  }

  @action incTransactionsPage(r = 1) {
    this.transactions_page += r;
  }

  @action setTransactionsFetching(s) {
    this.transactions_fetching = s;
  }

  @action setTransactions(s) {
    this.transactions = s;
  }

  @action setBusyState(state) {
    this.busy_count += state ? 1 : -1;
  }

  @action setShowAddAdModal(s) {
    this.showAddAdModal = s;
  }

  @action setBranding(value) {
    this.branding = value;
  }

  @action setDevice(device) {
    this.device = device;
  }

  @action setAuthenticated(value) {
    this.authenticated = value;
  }

  @action setAuthenticating(value) {
    this.authenticating = value;
  }

  showSuccess(message) {
    this.pushMessage(message, 'success');
  }

  showError(message) {
    this.pushMessage(message, 'danger');
  }

  showInfo(message) {
    this.pushMessage(message, 'info');
  }

  showWarning(message) {
    this.pushMessage(message, 'warn');
  }

  @action pushMessage(message, level) {
    this.messages.replace([{
      message: message,
      level: level
    }]);
  }

  @action setShowPlaceModal(s, for_filter = false) {
    this.showPlaceModal = s;
    this.placeModalForFilter = for_filter;
  }

  @action setLanguage(lng, request = true, first = false) {
    lng = lng || 'ru';
    this.language = lng;
    this.i18n.changeLanguage(lng);
    this.storage.saveLanguage(lng);

    if (!first) {
      if (lng === 'ru') this.showInfo('Язык сайта изменен на русский');
      else this.showInfo('Сайттын тили кыргызчага которулду');
    }

    if (this.authenticated && request) {
      requester.post(`/user/language`, {language: lng}).then((res) => {
      }).catch((e) => {
        console.log(e);
      });
    }
  }

  @action
  async setPlace(place, request = true) {
    this.showPlaceModal = false;
    if (place) this.storage.savePlace(place);
    this.place = place;
    if (this.authenticated && this.user) {
      if (request) await requester.post(`/user/place`, {place: place.id});
      this.user.place = place;
    }
  }

  @action setUser(user) {
    this.user = user ? new User(user) : null;
    if (this.user !== null) {
      if (this.user.place !== this.place) this.setPlace(user.place, false);
      if (this.user.language !== this.language) this.setLanguage(user.language, false);
      this.favorites = user.favorites.map(v => ({product_id: v.product_id, product_type: v.product_type}));
    }
  }

  @action setToken(token) {
    this.token = token;
    if (token === null) this.storage.clearToken();
    else this.storage.saveToken(token);
  }

  useI18n(v) {
    this.i18n = v;
  }

  hasAbility(ability) {
    return this.authenticated && this.user && (this.user.abilities.indexOf(ability) > -1 || this.user.abilities.indexOf('admin') > -1);
  }

  async useStorage(s) {
    this.storage = s;
    this.setToken(await s.loadToken());
    this.setLanguage(await s.loadLanguage(), true, true);
    this.setPlace(await s.loadPlace());
  }

  @action setFavorites(s) {
    this.favorites = s;
  }

  @action
  async toggleFavorite(pid, ptype = 'ad') {
    if (this.checkFavorite(pid, ptype)) {
      this.favorites = this.favorites.filter(v => !(v.product_type === ptype && v.product_id === pid));
      this.showWarning('Удалено!');
      await requester.post('/user/favorites/delete', {
        product_type: ptype,
        product_id: pid,
      });
    } else {
      this.favorites.push({product_id: pid, product_type: ptype});
      this.showSuccess('Добавлено!');
      await requester.post('/user/favorites/add', {
        product_type: ptype,
        product_id: pid,
      });
    }
  }

  checkFavorite = (pid, ptype) => {
    return this.favorites.filter(v => v.product_type === ptype && v.product_id === pid).length > 0;
  };

  async authenticate(provider, code) {
    this.setAuthenticating(true);
    try {
      const data = await requester.post(`/user/auth/${provider}`, {
        code,
        language: this.language,
        place: this.place ? this.place.id : null,
        device: JSON.stringify({
          platform: 'web',
          app_version: WEB_VERSION,
        }),
      });
      if (['registered', 'logged_in'].includes(data.status)) this.makeAuth(data.data, data.status);
    } catch (e) {
      this.setAuthenticated(false);
    } finally {
      this.setAuthenticating(false);
    }
  }

  async fetchTransactions() {
    if (this.transactions_fetching) return;
    this.setTransactionsFetching(true);
    this.setTransactionsPage(1);
    try {
      const data = await requester.get('/user/balance/transactions', {
        limit: 30,
        page: 1
      });
      if (data.status === 'success') this.setTransactions(data.data);
    } finally {
      this.setTransactionsFetching(false);
    }
  }

  async checkAuth() {
    this.setAuthenticating(true);
    try {
      let data = await requester.get('/user/get', {id: 0});
      if (data.status === 'success') {
        this.setUser(data.data);
        this.setAuthenticated(true);
      } else this.logOut();
    } catch (e) {
      console.log(e);
    } finally {
      this.setAuthenticating(false);
    }
  }

  logOut() {
    if (this.user) this.showInfo(this.i18n.t('toast.info.logOut', {name: this.user.full_name}));
    this.setAuthenticated(false);
    this.setUser(null);
    this.setToken(null);
  }

  makeAuth(data, status) {
    this.setUser(data.user);
    this.setAuthenticating(false);
    this.setAuthenticated(data.user !== null);
    this.setToken(data.token);
    this.showSuccess(this.i18n.t(`toast.success.${status}`, {name: data.user.full_name}));
  }
}
