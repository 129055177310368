import {action, observable} from "mobx";
import BaseModel from "./BaseModel";

class Ad extends BaseModel {
  @observable id = 0;
  @observable title_ru = '';
  @observable title_ky = '';
  @observable additionals = {};
  @observable main_image = '';
  @observable categories_path = [];
  @observable description_ru = '';
  @observable description_ky = '';
  @observable images = [];
  @observable raised_on = null;
  @observable published_on = null;
  @observable phones = [];
  @observable author = null;
  @observable status = '';

  @action setRaisedOn(r) {
    this.raised_on = r;
  }

  @action reject() {
    this.raised_on = null;
    this.published_on = null;
    this.status = 'rejected';
  }

  @action deleteImage(url) {
    this.images = this.images.filter(v => v !== url);
  }

  @action setImages(s) {
    this.images = s;
  }
}

export default Ad;
