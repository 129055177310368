import {observable, action} from "mobx";
import {requester} from '../utils';

export default class PlaceStore {
  storage = null;

  @observable items = observable.array([]);

  @action setItems(value, from_storage = false) {
    this.items = value;
    if (!from_storage) this.storage.savePlaces(value);
  }

  async useStorage(s) {
    this.storage = s;
    this.setItems(await s.loadPlaces(), true);
  }

  getById(id) {
    const r = this.items.filter(v => v.id === id);
    if (r.length > 0) return r[0];
    else return null;
  }

  findItemById(id) {
    const result = this.items.filter(v => v.id === id);
    if (result.length > 0) return result[0];
    else return null;
  }

  async fetchAllItems() {
    try {
      const data = await requester.get('/place/get');
      if (data.status === 'success') this.setItems(data.data);
    } catch (e) {
      console.log(e);
    }
  }
}
