export const CAR_MAKES = {
  'Toyota': [
    '4Runner',
    'Avalon',
    'Avensis',
    'Avensis',
    'Avensis',
    'Avensis',
    'Highlander',
    'Ipsum',
    'Ist',
    'Land Cruiser',
    'Land Cruiser Prado',
    'Mark II',
    'Prius',
    'RAV4',
    'Sequoia',
    'Windom',
    'Wish',
    '',
  ],
  'Honda': [
    'Accord',
    'Civic',
    'CR-V',
    'Fit',
    'Inspire',
    'Jazz',
    'Odyssey',
    'StepWGN',
    'Stream',
    '',
  ],
  'Lexus': [
    'ES',
    'GS',
    'GX',
    'LS',
    'LX',
    'RX',
    '',
  ],
  'Mercedes-Benz': [
    'C-Class',
    'E-Class',
    'G-Class',
    'GL-Class',
    'M-Class',
    'S-Class',
    'Vito',
    '',
  ],
  'Hyundai': [
    'Avante',
    'Getz',
    'Porter',
    'Solaris',
    'Sonata',
    'Starex',
    '',
  ],
  'BMW': [
    '3-Series',
    '5-Series',
    '7-Series',
    'X5',
    'X6',
    '',
  ],
  'Daewoo': [
    'Labo',
    'Lacetti',
    'Lanos',
    'Matiz',
    'Nexia',
    'Nubira',
    'Tico',
    '',
  ],
  'Opel': [
    'Astra',
    'Vectra',
    'Zafira',
    '',
  ],
  'Volkswagen': [
    'Golf',
    'Jetta',
    'Passat',
    'Transporter',
    'Vento',
    '',
  ],
  'Lada': [
    '',
  ],
  'Audi': [
    '',
  ],
  'Mazda': [
    '323',
    '626',
    'Demio',
    'MPV',
    'Premacy',
    '',
  ],
  'Nissan': [
    'Almera',
    'Cefiro',
    'Pathfinder',
    'Patrol',
    'Primera',
    'X-Trail',
    '',
  ],
  'Mitsubishi': [
    'Delica',
    'Galant',
    'Lancer',
    'Pajero',
    'Space Wagon',
    '',
  ],
  'Ford': [
    'Focus',
    'Mondeo',
    ''
  ],
  'Porsche': [
    '',
  ],
  'Renault': [
    '',
  ],
  'Kia': [
    '',
  ],
  'Chrysler': [
    '',
  ],
  'Cadillac': [
    '',
  ],
  'Chevrolet': [
    '',
  ],
  'Infiniti': [
    '',
  ],
  'Peugeot': [
    '',
  ],
  'Rover': [
    '',
  ],
  'Skoda': [
    '',
  ],
  '': [
    '',
  ],
};

export const MINIBUS_MAKES = [
  'Toyota',
  'Mercedes-Benz',
  'Volkswagen',
  'Nissan',
  'Ford',
  '',
];

export const TRUCK_MAKES = [
  'MAN',
  'SCANIA',
  'ДАФ',
  'ДУЛОНГ',
  'ШАХМАН',
  'МЕРСЕДЕС',
  'РЕНО',
  '',
];

export const COMPUTER_MAKES = [
  'Samsung',
  'Apple Mac',
  'Toshiba',
  'Acer',
  'Asus',
  'Dell',
  'Lenovo',
  'Sony',
  '',
];

export const PHONE_MAKES = [
  'Samsung',
  'Apple iPhone',
  'Xiaomi',
  'Huawei',
  'Lenovo',
  'Philips',
  'Nokia',
  'Sony',
  '',
];

export const VACANCIES = [
  {code: 1, ky: 'автомойщик', ru: 'автомойщик'},
  {code: 2, ky: 'официант', ru: 'официант'},
  {code: 3, ky: 'охранник', ru: 'охранник'},
  {code: 4, ky: 'няня', ru: 'няня'},
  {code: 5, ky: 'электрик', ru: 'электрик'},
  {code: 6, ky: 'бухгалтер', ru: 'бухгалтер'},
  {code: 7, ky: 'водитель', ru: 'водитель'},
  {code: 8, ky: 'повар', ru: 'повар'},
  {code: 9, ky: 'горничная', ru: 'горничная'},
  {code: 10, ky: 'грузчик', ru: 'грузчик'},
  {code: 11, ky: 'швея', ru: 'швея'},
  {code: 12, ky: 'администратор', ru: 'администратор'},
  {code: 13, ky: 'бариста', ru: 'бариста'},
  {code: 14, ky: 'бармен', ru: 'бармен'},
  {code: 15, ky: 'кладовщик', ru: 'кладовщик'},
  {code: 16, ky: 'курьер', ru: 'курьер'},
  {code: 17, ky: 'мастер маникюра', ru: 'мастер маникюра'},
  {code: 18, ky: 'машинист экскаватора', ru: 'машинист экскаватора'},
  {code: 19, ky: 'менеджер', ru: 'менеджер'},
  {code: 20, ky: 'монтажник', ru: 'монтажник'},
  {code: 21, ky: 'оператор', ru: 'оператор'},
  {code: 22, ky: 'парикмахер', ru: 'парикмахер'},
  {code: 23, ky: 'продавец-кассир', ru: 'продавец-кассир'},
  {code: 24, ky: 'промоутер', ru: 'промоутер'},
  {code: 25, ky: 'разнорабочий', ru: 'разнорабочий'},
  {code: 26, ky: 'сварщик', ru: 'сварщик'},
  {code: 27, ky: 'сиделка', ru: 'сиделка'},
  {code: 28, ky: 'сторож', ru: 'сторож'},
  {code: 29, ky: 'торговый представитель', ru: 'торговый представитель'},
  {code: 30, ky: 'уборщица', ru: 'уборщица'},
  {code: 31, ky: 'флорист', ru: 'флорист'},
  {code: 32, ky: 'другие специальности', ru: 'другие специальности'},
];

export const SCHEDULES_OF_VACANCY = [
  {code: 1, ky: 'вахтовый метод', ru: 'вахтовый метод'},
  {code: 2, ky: 'неполный день', ru: 'неполный день'},
  {code: 3, ky: 'полный день', ru: 'полный день'},
  {code: 4, ky: 'свободный график', ru: 'свободный график'},
  {code: 5, ky: 'сменный график', ru: 'сменный график'},
  {code: 6, ky: 'удалённая работа', ru: 'удалённая работа'},
];

export const EXPERIENCES_OF_VACANCY = [
  {code: 1, ky: 'не имеет значения', ru: 'не имеет значения'},
  {code: 2, ky: 'более 1 года', ru: 'более 1 года'},
  {code: 3, ky: 'более 3 лет', ru: 'более 3 лет'},
  {code: 4, ky: 'более 5 лет', ru: 'более 5 лет'},
  {code: 5, ky: 'более 10 лет', ru:'более 10 лет'},
];

export const SUMMARY = [
  {
    "code": 1,
    "ky": "Производство/энергетика",
    "ru": "Производство/энергетика",
  },
  {
    "code": 2,
    "ky": "Бары/рестораны/гостиницы",
    "ru": "Бары/рестораны/гостиницы",
  },
  {
    "code": 3,
    "ky": "Розничная торговля/продажи",
    "ru": "Розничная торговля/продажи",
  },
  {
    "code": 4,
    "ky": "Строительство",
    "ru": "Строительство",
  },
  {
    "code": 5,
    "ky": "Транспорт/логистика",
    "ru": "Транспорт/логистика",
  },
  {
    "code": 6,
    "ky": "Сервис и быт",
    "ru": "Сервис и быт",
  },
  {
    "code": 7,
    "ky": "Образование",
    "ru": "Образование",
  },
  {
    "code": 8,
    "ky": "Электрики/сантехники",
    "ru": "Электрики/сантехники",
  },
  {
    "code": 9,
    "ky": "Перевод",
    "ru": "Перевод",
  },
  {
    "code": 10,
    "ky": "Стоматология",
    "ru": "Стоматология",
  },
  {
    "code": 11,
    "ky": "Разнорабочие",
    "ru": "Разнорабочие",
  },
  {
    "code": 12,
    "ky": "Юриспруденция",
    "ru": "Юриспруденция",
  },
  {
    "code": 13,
    "ky": "Бухгалтерия/финансы",
    "ru": "Бухгалтерия/финансы",
  },
  {
    "code": 14,
    "ky": "IТ/телеком/компьютеры",
    "ru": "IТ/телеком/компьютеры",
  },
  {
    "code": 15,
    "ky": "Красота/фитнес/спорт",
    "ru": "Красота/фитнес/спорт",
  },
  {
    "code": 16,
    "ky": "Домашний персонал/уборка",
    "ru": "Домашний персонал/уборка",
  },
  {
    "code": 17,
    "ky": "Медицина/фармацевтика",
    "ru": "Медицина/фармацевтика",
  },
  {
    "code": 18,
    "ky": "Работа за границей",
    "ru": "Работа за границей",
  },
  {
    "code": 19,
    "ky": "Управление персоналом",
    "ru": "Управление персоналом",
  },
  {
    "code": 20,
    "ky": "Автомобильный бизнес",
    "ru": "Автомобильный бизнес",
  },
  {
    "code": 21,
    "ky": "Маркетинг/реклама/дизайн",
    "ru": "Маркетинг/реклама/дизайн",
  },
  {
    "code": 22,
    "ky": "Швейное дело",
    "ru": "Швейное дело",
  },
  {
    "code": 23,
    "ky": "Банки/страхование",
    "ru": "Банки/страхование",
  },
  {
    "code": 24,
    "ky": "Секретариат/госслужба",
    "ru": "Секретариат/госслужба",
  },
  {
    "code": 25,
    "ky": "Туризм/отдых/развлечение",
    "ru": "Туризм/отдых/развлечение",
  },
  {
    "code": 26,
    "ky": "Начало карьеры/студенты",
    "ru": "Начало карьеры/студенты",
  },
  {
    "code": 27,
    "ky": "Охрана/безопасность",
    "ru": "Охрана/безопасность",
  },
  {
    "code": 28,
    "ky": "Культура/искусство",
    "ru": "Культура/искусство",
  },
  {
    "code": 29,
    "ky": "Другие специальности",
    "ru": "Другие специальности",
  },
];

export const EDUCATION_OF_SUMMARY = [
  {code: 1, ky: 'высшее', ru: 'высшее'},
  {code: 2, ky: 'незаконченное высшее', ru: 'незаконченное высшее'},
  {code: 3, ky: 'среднее', ru: 'среднее'},
  {code: 4, ky: 'среднее специальное', ru: 'среднее специальное'},
];

export const GENDER = [
  {code: 1, ky: 'женский', ru: 'женский'},
  {code: 2, ky: 'мужской', ru: 'мужской'},
];
