import React from 'react';
import {Button} from "react-bootstrap";

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '200px'
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  }
};

export default class Error extends React.Component {
  constructor(props) {
    super(props);

    this.retryButton = this.retryButton.bind(this);
  }

  render(){
    return (
      <div style={styles.container}>
        <div style={styles.content}>
          <h3>Ошибка!</h3>
          {this.props.description && <div>{this.props.description}</div>}
          {this.retryButton()}
        </div>
      </div>
    );
  }

  retryButton() {
    if (this.props.retryF) {
      return <Button className={'mt-3'} onClick={this.props.retryF}>Retry</Button>
    }
    return null;
  }
}
