import {observable, action} from "mobx";
import {requester} from '../utils';

export default class CategoryStore {
  storage = null;

  @observable items = observable.array([]);

  @action setItems(value, fromStorage = false) {
    this.items = value;
    if (!fromStorage) this.storage.saveCategories(value);
  }

  searchById(id, items = [], found_item) {
    items.map(v => {
      if (v.id === id) found_item(v);
      else if (v.children.length > 0) this.searchById(id, v.children, found_item);
    });
  }

  getById(id) {
    let found_item = null;
    this.searchById(id, this.items, f => found_item = f);
    return found_item;
  }

  async fetchById(id){
    const data = await requester.get('/category/get_by_id', {id});
    if (data.status === 'success') return data.data;
    else throw new Error(data.status);
  }

  async useStorage(s) {
    this.storage = s;
    this.setItems(await s.loadCategories(), true);
  }

  async getAllItems() {
    try {
      const data = await requester.get('/category/get');
      if (data.status === 'success') this.setItems(data.data);
    } catch (e) {
      console.log(e);
    }
  }
}
