import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Button, FormControl, Modal} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import AddAdCategoriesComponent from '../AddAdCategoriesComponent';
import AddAdDefaultQuestionsComponent from '../AddAdDefaultQuestionsComponent';
import {observe} from 'mobx';
import AddAdAdditionalQuestions from '../AddAdQuestionsComponent';
import {codeTranslator} from "../../utils";

@withTranslation() @inject('store') @observer
class AddAdModalComponent extends Component {
  constructor(props){
    super(props);

    this.store = this.props.store.appStore;
    this.categoryStore = this.props.store.categoryStore;
    this.adStore = this.props.store.adStore;

    this.closeModal = this.closeModal.bind(this);

    this.prevQuestionHandler = this.prevQuestionHandler.bind(this);
    this.nextQuestionHandler = this.nextQuestionHandler.bind(this);

    const Component = AddAdAdditionalQuestions[`AddAdPlaceQuestion`];
    if (!Component) return null;
    const place_component =  <Component t={this.props.t} nextQuestion={this.nextQuestionHandler} prevQuestion={this.prevQuestionHandler} />;
    this.components = [place_component];

    observe(this.adStore, "addAdForms", this.onChangeForms.bind(this));
    observe(this.adStore, "addAdCurrentQuestion", this.onChangeCurrentQuestion.bind(this));
  }

  onChangeCurrentQuestion(nv){
    let {newValue} = nv;
    this.adStore.setAddAdCurrentShow(this.adStore.addAdForms[newValue]);
  }

  onChangeForms(nv){
    let {newValue} = nv;
    let props = {
      t: this.props.t,
      nextQuestion: this.nextQuestionHandler,
      prevQuestion: this.prevQuestionHandler,
    };
    this.components = newValue.map((f, i) => {
      const Component = AddAdAdditionalQuestions[`AddAdPlaceQuestion`];
      if (!Component) return null;
      return <Component key={i} {...props} />;
    });
  }

  nextQuestionHandler(){
    this.adStore.setAddAdCurrentQuestion(this.adStore.addAdCurrentQuestion + 1);
  }

  prevQuestionHandler(){
    if (this.adStore.addAdCurrentQuestion === -1){
      this.adStore.clearAddAdForm();
    } else {
      this.adStore.setAddAdCurrentQuestion(this.adStore.addAdCurrentQuestion - 1);
    }
  }

  closeModal(){
    if (window.confirm('Вы уверены, что закрыть?')){
      this.store.setShowAddAdModal(false);
      setTimeout(() => {
        this.adStore.clearAddAdForm();
      }, 500);
    }
  }

  titleInputHandler = (e) => {
    this.adStore.setAddAdTitle(e.target.value.length > 70 ? e.target.value.substr(0, 70) : e.target.value);
  };

  render(){
    const { t } = this.props;

    return (
      <Modal show={this.store.showAddAdModal} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">{t("modal.title.addAd")}</Modal.Title>
        </Modal.Header>
        {this.adStore.addAdCurrentQuestion === -1 && (
          <React.Fragment>
            <Modal.Body>
              <div className="h4 d-flex justify-content-between align-items-center">
                <div>{t("_.Headline")}</div>
                <div className={`text-${this.adStore.addAdTitle.length >= 70 ? 'danger' : 'muted'} h5`}>{this.adStore.addAdTitle.length}/70</div>
              </div>
              <FormControl
                type="text"
                isInvalid={this.adStore.addAdTitle.length >= 70}
                size="sm"
                onChange={this.titleInputHandler}
                value={this.adStore.addAdTitle}
                placeholder={t("_.Headline")}
                aria-label={`Заголовок`}
              />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button size="sm" variant="secondary" disabled>
                {t("buttonLabel.addAdModal.prevQuestion")}
              </Button>
              <Button disabled={this.adStore.addAdTitle.length < 1} size="sm" variant="primary" onClick={this.nextQuestionHandler}>
                {t("buttonLabel.addAdModal.nextQuestion")}
              </Button>
            </Modal.Footer>
          </React.Fragment>
        )}
        {this.adStore.addAdCurrentQuestion >= 0 && this.components}
        {(this.adStore.addAdForms.length - 1) < this.adStore.addAdCurrentQuestion && (
          <AddAdDefaultQuestionsComponent
            question={this.adStore.addAdCurrentQuestion - this.adStore.addAdForms.length}
            t={t}
            userContacts={this.store.user.phones}
            nextQuestion={this.nextQuestionHandler}
            prevQuestion={this.prevQuestionHandler} />
        )}
      </Modal>
    );
  }
}

export default AddAdModalComponent;
