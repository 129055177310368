import AppStore from './AppStore';
import AdStore from './AdStore';
import CategoryStore from './CategoryStore';
import PlaceStore from './PlaceStore';
import AdminStore from "./AdminStore";
import SearchStore from "./SearchStore";

const appStore = new AppStore();

const stores = {
  appStore,
  adStore: new AdStore(appStore),
  categoryStore: new CategoryStore(appStore),
  placeStore: new PlaceStore(appStore),
  adminStore: new AdminStore(appStore),
  searchStore: new SearchStore(appStore),
};

export default stores;
