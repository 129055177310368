import React from 'react';
import {inject, observer} from 'mobx-react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import {
  CAR_MAKES,
  EXPERIENCES_OF_VACANCY,
  SCHEDULES_OF_VACANCY, SUMMARY,
  VACANCIES,
  EDUCATION_OF_SUMMARY,
  GENDER,
} from '../data';
import Questions from "./ads/Questions";
import autobind from "autobind-decorator";
import {codeTranslator} from "../utils";

@inject('store')
@observer
class AddAdPriceQuestion extends React.Component {
  form_name = 'price';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;

    this.state = {value: null};
  }

  @autobind nextQuestion() {
    this.adStore.setAddAdAdditionals(this.form_name, this.state.value);
    this.props.nextQuestion();
  }

  @autobind prevQuestion() {
    this.adStore.setAddAdAdditionals(this.form_name, this.state.value);
    this.props.prevQuestion();
  }

  @autobind onValidValue(value) {
    this.setState({value});
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.onValidValue}
            nextQuestion={this.nextQuestion}
            prevQuestion={this.prevQuestion}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button size="sm" variant="primary" onClick={this.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdAgeOfSummaryQuestion extends React.Component {
  form_name = 'age_of_summary';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;

    this.state = {value: ''};
  }

  @autobind nextQuestion() {
    this.adStore.setAddAdAdditionals(this.form_name, this.state.value);
    this.props.nextQuestion();
  }

  @autobind prevQuestion() {
    this.adStore.setAddAdAdditionals(this.form_name, this.state.value);
    this.props.prevQuestion();
  }

  @autobind onValidValue(value) {
    this.setState({value});
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.onValidValue}
            onInvalidValue={() => this.setState({value: ''})}
            nextQuestion={this.nextQuestion}
            prevQuestion={this.prevQuestion}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={this.state.value.length < 1} size="sm" variant="primary" onClick={this.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store') @observer
class AddAdWageOfVacancyQuestion extends React.Component {
  form_name = 'wage_of_vacancy';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;

    this.state = {value: null};
  }

  @autobind nextQuestion() {
    this.adStore.setAddAdAdditionals(this.form_name, this.state.value);
    this.props.nextQuestion();
  }

  @autobind prevQuestion() {
    this.adStore.setAddAdAdditionals(this.form_name, this.state.value);
    this.props.prevQuestion();
  }

  @autobind onValidValue(value) {
    this.setState({value});
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.onValidValue}
            nextQuestion={this.nextQuestion}
            prevQuestion={this.prevQuestion}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button size="sm" variant="primary" onClick={this.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdPlaceQuestion extends React.Component {
  form_name = 'place';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  @autobind onValidValue(value) {
    this.adStore.setAddAdAdditionals(this.form_name, value);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.onValidValue}
            nextQuestion={this.props.nextQuestion}
            prevQuestion={this.props.prevQuestion}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button size="sm"
                  disabled={!this.adStore.addAdAdditionals[this.form_name]}
                  variant="primary" onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdRoomsQuestion extends React.Component {
  form_name = 'rooms';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  @autobind onValidValue(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.onValidValue}
            nextQuestion={this.props.nextQuestion}
            prevQuestion={this.props.prevQuestion}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdFurnitureQuestion extends React.Component {
  form_name = 'furniture';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  @autobind onValidValue(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.onValidValue}
            nextQuestion={this.props.nextQuestion}
            prevQuestion={this.props.prevQuestion}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button size="sm"
                  disabled={typeof this.adStore.addAdAdditionals[this.form_name] !== 'boolean'}
                  variant="primary" onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdAgencyOrOwnerQuestion extends React.Component {
  form_name = 'agency_or_owner';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  @autobind onValidValue(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.onValidValue}
            nextQuestion={this.props.nextQuestion}
            prevQuestion={this.props.prevQuestion}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdAreaHundredthsQuestion extends React.Component {
  form_name = 'area_hundredths';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  @autobind onValidValue(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">Площадь цифры сотых</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.onValidValue}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={() => this.props.prevQuestion()}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button size="sm" variant="primary" onClick={() => this.props.nextQuestion()}
                  disabled={this.form_name in this.adStore.addAdAdditionals ? +this.adStore.addAdAdditionals[this.form_name] < 1 : true}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdAreaSquareMeterQuestion extends React.Component {
  form_name = 'area_square_meter';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  onValidValue = (t) => {
    this.adStore.setAddAdAdditionals(this.form_name, t);
  };

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">Площадь — цифры кв.м.</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.onValidValue}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={() => this.props.prevQuestion()}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button size="sm" variant="primary" onClick={() => this.props.nextQuestion()}
                  disabled={this.form_name in this.adStore.addAdAdditionals ? +this.adStore.addAdAdditionals[this.form_name] < 1 : true}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdCarMakeQuestion extends React.Component {
  form_name = 'car_make';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">Марка</div>
          <Form.Control as="select" onChange={v => this.setData(v.target.value)}>
            <option disabled={true} selected={!this.adStore.addAdAdditionals[this.form_name]}>Выберите</option>
            {Object.keys(CAR_MAKES).map((v, key) => (
              <option value={v} key={key} selected={this.adStore.addAdAdditionals[this.form_name] === v}>{v || t(`adQuestions.${this.form_name}.another`)}</option>
            ))}
          </Form.Control>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdCarModelQuestion extends React.Component {
  form_name = 'car_model';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">Модель</div>
          <Form.Control as="select" onChange={v => this.setData(v.target.value)}>
            <option disabled={true} selected={!this.adStore.addAdAdditionals[this.form_name]}>Выберите</option>
            {CAR_MAKES[this.adStore.addAdAdditionals['car_make']].map((v, key) => (
              <option value={v} key={key} selected={this.adStore.addAdAdditionals[this.form_name] === v}>{v || t(`adQuestions.${this.form_name}.another`)}</option>
            ))}
          </Form.Control>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdYearOfIssueQuestion extends React.Component {
  form_name = 'year_of_issue';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData = (t) => {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  };

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.setData}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdGearTypeQuestion extends React.Component {
  form_name = 'gear_type';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData = (t) => {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  };

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.setData}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdFuelQuestion extends React.Component {
  form_name = 'fuel';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData = (t) => {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  };

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.setData}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdVacancyQuestion extends React.Component {
  form_name = 'vacancy';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
    this.appStore = this.props.store.appStore;
  }

  setData(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Row>
            {VACANCIES.map((v, key) => (
              <Col xs={6} key={key}>
                <Button size="sm" className="w-100 m-2" onClick={() => {
                  this.setData(v.code);
                }} variant={this.adStore.addAdAdditionals[this.form_name] === v.code ? 'info' : 'light'}>{v[this.appStore.language]}</Button>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdEducationOfSummaryQuestion extends React.Component {
  form_name = 'education_of_summary';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
    this.appStore = this.props.store.appStore;
  }

  setData(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Row>
            {EDUCATION_OF_SUMMARY.map((v, key) => (
              <Col xs={6} key={key} className={'d-flex align-items-center'}>
                <Button size="sm" className="w-100 m-2" onClick={() => {
                  this.setData(v.code);
                }} variant={this.adStore.addAdAdditionals[this.form_name] === v.code ? 'info' : 'light'}>{v[this.appStore.language]}</Button>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdGenderQuestion extends React.Component {
  form_name = 'gender';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
    this.appStore = this.props.store.appStore;
  }

  setData(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Row>
            {GENDER.map((v, key) => (
              <Col xs={6} key={key} className={'d-flex align-items-center'}>
                <Button size="sm" className="w-100 m-2" onClick={() => this.setData(v.code)} variant={this.adStore.addAdAdditionals[this.form_name] === v.code ? 'info' : 'light'}>{v[this.appStore.language]}</Button>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdSummaryQuestion extends React.Component {
  form_name = 'summary';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
    this.appStore = this.props.store.appStore;
  }

  setData(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Row>
            {SUMMARY.map((v, key) => (
              <Col xs={6} key={key} className={'d-flex align-items-center'}>
                <Button size="sm" className="w-100 m-2" onClick={() => {
                  this.setData(v.code);
                }} variant={this.adStore.addAdAdditionals[this.form_name] === v.code ? 'info' : 'light'}>{v[this.appStore.language]}</Button>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdScheduleOfVacancyQuestion extends React.Component {
  form_name = 'schedule_of_vacancy';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
    this.appStore = this.props.store.appStore;
  }

  setData(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Row>
            {SCHEDULES_OF_VACANCY.map((v, key) => (
              <Col xs={6} key={key}>
                <Button size="sm" className="w-100 m-2" onClick={() => {
                  this.setData(v.code);
                }} variant={this.adStore.addAdAdditionals[this.form_name] === v.code ? 'info' : 'light'}>{v[this.appStore.language]}</Button>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdExperienceOfVacancyQuestion extends React.Component {
  form_name = 'experience_of_vacancy';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
    this.appStore = this.props.store.appStore;
  }

  setData(t) {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  }

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Row>
            {EXPERIENCES_OF_VACANCY.map((v, key) => (
              <Col xs={6} key={key}>
                <Button size="sm" className="w-100 m-2" onClick={() => {
                  this.setData(v.code);
                }} variant={this.adStore.addAdAdditionals[this.form_name] === v.code ? 'info' : 'light'}>{v[this.appStore.language]}</Button>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdMinibusMakeQuestion extends React.Component {
  form_name = 'minibus_make';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData = (t) => {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  };

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">Марка</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.setData}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdTruckMakeQuestion extends React.Component {
  form_name = 'truck_make';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData = (t) => {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  };

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">Марка</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.setData}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdStateQuestion extends React.Component {
  form_name = 'state';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData = (t) => {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  };

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t(`adQuestions.add_form_names.${this.form_name}`)}</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.setData}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdPhoneMakeQuestion extends React.Component {
  form_name = 'phone_make';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData = (t) => {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  };

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">Марка</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.setData}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdComputerMakeQuestion extends React.Component {
  form_name = 'computer_make';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData = (t) => {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  };

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <div className="h4">Марка</div>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.setData}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class AddAdActionQuestion extends React.Component {
  form_name = 'action';

  constructor(props) {
    super(props);

    this.adStore = this.props.store.adStore;
  }

  setData = (t) => {
    this.adStore.setAddAdAdditionals(this.form_name, t);
    this.props.nextQuestion();
  };

  render() {
    if (this.adStore.addAdCurrentShow !== this.form_name) return null;

    const {t} = this.props;
    const Question = Questions[`${codeTranslator(this.form_name)}Question`];

    return (
      <React.Fragment>
        <Modal.Body>
          <Question
            defaultValue={this.adStore.addAdAdditionals[this.form_name]}
            onValidValue={this.setData}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" variant="secondary" onClick={this.props.prevQuestion}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button disabled={!this.adStore.addAdAdditionals[this.form_name]} size="sm" variant="primary"
                  onClick={this.props.nextQuestion}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

export default {
  AddAdPriceQuestion,
  AddAdPlaceQuestion,
  AddAdRoomsQuestion,
  AddAdFurnitureQuestion,
  AddAdAgencyOrOwnerQuestion,
  AddAdAreaHundredthsQuestion,
  AddAdAreaSquareMeterQuestion,
  AddAdCarMakeQuestion,
  AddAdCarModelQuestion,
  AddAdYearOfIssueQuestion,
  AddAdGearTypeQuestion,
  AddAdFuelQuestion,
  AddAdMinibusMakeQuestion,
  AddAdTruckMakeQuestion,
  AddAdStateQuestion,
  AddAdPhoneMakeQuestion,
  AddAdComputerMakeQuestion,
  AddAdActionQuestion,
  AddAdVacancyQuestion,
  AddAdScheduleOfVacancyQuestion,
  AddAdExperienceOfVacancyQuestion,
  AddAdWageOfVacancyQuestion,
  AddAdSummaryQuestion,
  AddAdEducationOfSummaryQuestion,
  AddAdGenderQuestion,
  AddAdAgeOfSummaryQuestion,
};
