import {Button, Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import InputMask from "react-input-mask";
import React from 'react';
import ConfirmPhoneNumber from "./ConfirmPhoneNumber";
import autobind from "autobind-decorator";

class PhoneRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm_modal: null,
    };

  }

  @autobind
  onChangePhone(e) {
    const phone = e.target.value.replace('+', '').replace(/ /g, '');
    this.props.onChange({
      phone,
      whatsapp: this.props.phone.whatsapp,
      confirmed: this.props.phone.confirmed,
      exists: phone.length === 12,
    });
  }

  @autobind
  onChangeWhatsapp(e) {
    const whatsapp = e.target.checked;
    this.props.onChange({
      phone: this.props.phone.phone,
      whatsapp,
      confirmed: this.props.phone.confirmed,
      exists: this.props.phone.phone.length === 12,
    });
  }

  @autobind
  confirm() {
    if (this.props.hideConfirmButton) return;
    this.setState({
      confirm_modal: {
        phone: this.props.phone,
        code: this.props.code,
      }
    });
  }

  render() {
    const {props} = this;

    return (
      <React.Fragment>
        <div>
          <Row className="d-flex align-items-center">
            <Col xs={12} lg={7} sm={5} xl={8} md={6}>
              <InputMask mask="+\9\96 999 999 999" maskChar="" value={this.props.phone.phone} onChange={this.onChangePhone}>
                {(inputProps) => (
                  <InputGroup size="sm" className="mb-3">
                    <FormControl
                      {...inputProps}
                      disabled={props.disabled}
                      placeholder={`Телефон номер ${props.code}`}
                      aria-label={`Телефон номер ${props.code}`}
                    />
                  </InputGroup>
                )}
              </InputMask>
            </Col>
            <Col className="pl-0" xs={12} lg={5} sm={7} xl={4} md={6}>
              <Form.Group className="ml-3 mb-3" controlId={`formBasicChecbox${props.code}`}>
                {!this.props.hideConfirmButton && (
                  <Button disabled={props.disabled || this.props.phone.phone.length !== 12 || this.props.phone.confirmed}
                        onClick={this.confirm} className={'d-inline'} variant={'warning'}
                        size={'sm'}>Подтвердить</Button>
                )}
                <Form.Check className={'d-inline ml-3'} onChange={this.onChangeWhatsapp} checked={this.props.phone.whatsapp}
                            type="checkbox" label={props.t('form.profile.contacts.whatsapp')}/>
              </Form.Group>
            </Col>
          </Row>
        </div>

        <ConfirmPhoneNumber phone={this.state.confirm_modal} onHide={() => this.setState({confirm_modal: null})}/>
      </React.Fragment>
    );
  }
}

export default PhoneRenderer;
