import React from "react";
import i18n from '../i18n';
import {EDUCATION_OF_SUMMARY, EXPERIENCES_OF_VACANCY, GENDER, SCHEDULES_OF_VACANCY, SUMMARY, VACANCIES} from "../data";
import {ruAgeToWord} from "./index";

// TODO: questions fill required below

export function additionalQuestionItemRenderer(value, data, store) {
  const data_from_render = additionalQuestionRenderer({name: value, value: data}, store);
  return (
    <div>
      {data_from_render.title && <strong>{data_from_render.title}: </strong>}{data_from_render.content}
    </div>
  );
}

export function additionalQuestionsRenderer(items, store) {
  const result = [];
  for (const [name, value] of Object.entries(items)) {
    result.push(additionalQuestionRenderer({name, value}, store));
  }
  return result;
}

export function additionalQuestionRenderer({name, value}, store) {
  const key = `adQuestions.formNames.${name}`;

  let title = i18n.exists(key) ? i18n.t(key) : null;
  let content = name;
  let icon = 'faQuoteRight';

  switch (name) {
    case 'furniture':
      icon = 'faChair';
      content = value ? <span className="text-success">{i18n.t('adQuestions.has')}</span> :
        <span className="text-danger">{i18n.t(`adQuestions.has_not`)}</span>;
      break;

    case 'rooms':
      icon = 'faHotel';
      content = value;
      break;

    case 'place':
      icon = 'faMapMarkerAlt';
      content = value[`title_${i18n.t(`lang`)}`];
      if (value.parent) {
        content = `${store.placeStore.findItemById(value.parent)[`title_${i18n.t('lang')}`]}, ${content}`;
      }
      break;

    case 'experience_of_vacancy':
      content = EXPERIENCES_OF_VACANCY[value - 1][i18n.t('lang')];
      break;

    case 'schedule_of_vacancy':
      content = SCHEDULES_OF_VACANCY[value - 1][i18n.t('lang')];
      break;

    case 'education_of_summary':
      icon = 'faGraduationCap';
      content = EDUCATION_OF_SUMMARY[value - 1][i18n.t('lang')];
      break;

    case 'vacancy':
      content = VACANCIES[value - 1][i18n.t('lang')];
      break;

    case 'gender':
      icon = 'faVenusMars';
      content = GENDER[value - 1][i18n.t('lang')];
      break;

    case 'summary':
      content = SUMMARY[value - 1][i18n.t('lang')];
      break;

    case 'price':
      icon = 'faDollarSign';
      content = !value ? i18n.t('adQuestions.negotiable') : value.price + ' ' + value.currency;
      break;

    case 'wage_of_vacancy':
      icon = 'faDollarSign';
      content = !value ? i18n.t('adQuestions.negotiable') : value.price + ' ' + value.currency;
      break;

    case 'age_of_summary':
      content = `${value} ${ruAgeToWord(value)}`;
      break;

    case 'floor':
      icon = 'faHome';
      content = value;
      break;

    case 'agency_or_owner':
      icon = 'faUser';
      content = i18n.t(`adQuestions.${value === 'agency' ? 'agency' : 'owner'}`);
      break;

    case 'area_hundredths':
      icon = 'faChartArea';
      content = `${value} сотых`;
      break;

    case 'area_square_meter':
      icon = 'faChartArea';
      content = `${value} кв метр`;
      break;

    case 'car_make':
      icon = 'faCar';
      content = value;
      break;

    case 'car_model':
      icon = 'faCar';
      content = value;
      break;

    case 'year_of_issue':
      content = value;
      break;

    case 'fuel':
      icon = 'faGasPump';
      content = i18n.t(`adQuestions.${name}.${value}`);
      break;

    case 'gear_type':
      content = i18n.t(`adQuestions.${name}.${value}`);
      break;

    case 'minibus_make':
      icon = 'faTruckMoving';
      content = value;
      break;

    case 'truck_make':
      icon = 'faTruckMoving';
      content = value;
      break;

    case 'state':
      content = i18n.t(`adQuestions.${name}.${value}`);
      break;

    case 'phone_make':
      icon = 'faMobileAlt';
      content = value;
      break;

    case 'computer_make':
      icon = 'faLaptop';
      content = value;
      break;

    case 'action':
      icon = 'faHandshake';
      content = i18n.t(`adQuestions.${name}.${value}`);
      break;

    default:
      break;
  }

  return {title, content, icon, name, value};
}

export function getProviderName(provider) {
  if (
    provider === 'instagram' ||
    provider === 'facebook' ||
    provider === 'google'
  ) return provider.charAt(0).toUpperCase() + provider.slice(1);
  if (provider === 'vkontakte') return 'ВКонтакте';
  else if (provider === 'odnoklassniki') return 'Одноклассники';
  else if (provider === 'phone') return 'Телефон номер';
  return 'Unknown';
}
