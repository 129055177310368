import React from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";

class SelectPlaceModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      history: [],
    };
  }

  onHide = () => {
    if (this.props.item) this.props.setPlace(this.props.item);
  };

  onSelect = (v, anyway = false) => {
    // if (v.children.length > 0 && !anyway) {
    //   this.setState((prevState) => {
    //     prevState['history'].push(v);
    //     return prevState;
    //   });
    // } else this.props.setPlace(v);
    this.props.setPlace(v);
    if (this.props.for_filter && this.props.adStore.filter_is_disabled) this.props.adStore.toggleFilter();
  };

  prev = () => {
    if (this.state.history.length === 0) return;
    this.setState((prevState) => {
      prevState['history'].splice(-1, 1);
      return prevState;
    });
  };

  render() {
    return (
      <Modal centered show={this.props.show} onHide={this.onHide.bind(this)}>
        <Modal.Body>
          {this.state.history.length === 0 && (
            <>
              {!this.props.for_filter && (
                <>
                  <div className="text-center p-3">
                    <div className="mb-4"><img width={'164px'} src={'/images/logo-684x301.png'}/></div>
                    <div className="h5">{this.props.language === 'ky' ? 'Сиздин аймак' : 'Ваш город'}</div>
                  </div>
                  <div className="pb-3">
                    {this.props.language === 'ky' ? 'Сиз жашаган же керектүү аймакты тандаңыз. Тандалган аймактагы жарыялар көрсөтүлөт.' : 'Пожалуйста, дайте нам знать, откуда вы. Это помогает нам показывать вам те объявления, которые вам ближе.'}
                  </div>
                </>
              )}
            </>
          )}
          <Row>
            {this.props.for_filter && (
              <Col sm="12">
                <Button size="sm" className="w-100 m-2"
                        onClick={() => {
                          this.props.adStore.toggleFilter();
                          this.props.setValueForAppStore('showPlaceModal', false);
                        }}
                        variant={this.props.adStore.filter_is_disabled ? 'primary' : 'light'}>Весь Кыргызстан</Button>
              </Col>
            )}
            {(this.state.history.length > 0 && this.state.history[this.state.history.length - 1]) && (
              <Col sm="12">
                <Button size="sm" className="w-100 m-2"
                        onClick={() => this.onSelect(this.state.history[this.state.history.length - 1], true)}
                        variant={(this.props.item && this.props.item.id === this.state.history[this.state.history.length - 1].id) ? 'primary' : 'light'}>{this.state.history[this.state.history.length - 1][`title_${this.props.language}`]}</Button>
              </Col>
            )}
            {(this.state.history.length === 0 ? this.props.items : this.state.history[this.state.history.length - 1].children).map((value, index) => (
              <Col key={index} sm="6">
                <Button size="sm" className="w-100 m-2" onClick={() => this.onSelect(value)}
                        variant={(this.props.item && this.props.item.id === value.id && (!this.props.for_filter || !this.props.adStore.filter_is_disabled)) ? 'primary' : 'light'}>{value[`title_${this.props.language}`]}</Button>
              </Col>
            ))}
            {(this.state.history.length > 0 && this.state.history[this.state.history.length - 1]) && (
              <Col sm="12">
                <Button size="sm" className="w-100 m-2" onClick={() => this.prev()} variant={'warning'}>Назад</Button>
              </Col>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SelectPlaceModalComponent;
