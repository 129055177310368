import {observable, action, computed} from "mobx";
import {requester} from "../utils";

export default class SearchStore {
  @observable query = '';
  @observable status = '';
  @observable page = 1;
  @observable items = observable.array([]);
  @observable selected_place = 0;
  @observable selected_category = 0;

  @action setQuery(s = '') {
    this.query = s;
  }

  @action setItems(s) {
    this.items = s;
  }

  @action clear() {
    this.query = '';
    this.status = '';
    this.items = [];
    this.selected_place = 0;
    this.selected_category = 0;
  }

  @action setStatus(s = '') {
    this.status = s;
  }

  @action setValue = (name, value) => this[name] = value;

  @computed get searchable() {
    return this.status !== 'searching' && this.query.length >= 3;
  }

  @computed get count_of_items() {
    return this.items.length;
  }

  async search() {
    if (this.query.length > 0) {
      try {
        this.setStatus('searching');
        const data = await requester.get('/ad/search', {
          query: this.query,
          filters: {
            place: this.selected_place,
            category: this.selected_category,
          },
        });
        this.setItems(data.data.items);
        this.setStatus('searched');
      } catch (e) {
        this.setStatus('error');
      }
    }
  }
}
