import React from "react";
import {Route} from "react-router-dom";
import LazyRoute from "./ui/LazyRoute";

export const routers = [
  {path: "/", component: "containers/HomeContainer", exact: true},
  {path: "/contacts", component: "screens/ContactsScreen", exact: true},
  {path: "/ads", component: "screens/ads", exact: false},
  {path: "/details", component: "screens/ads", exact: false},
  {path: "/users", component: "screens/users", exact: false},
  {path: "/balance", component: "screens/balance", exact: false},
  {path: "/terms", component: "screens/TermsScreen", exact: true},
  {path: "/about_us", component: "components/app/AboutUsComponent", exact: true},
  {path: "/help", component: "screens/HelpScreen", exact: true},
  {path: "/auth", component: "screens/AuthScreen", exact: true},
  {path: "/profile", component: "containers/ProfileContainer", exact: true},
  {path: "/favorites", component: "screens/FavoritesScreen", exact: true},
  {path: "/admin", component: "screens/admin", exact: false},
];

export default () => (
  <React.Fragment>
    {routers.map((router) => (
      <Route
        path={router.path}
        exact={router.exact}
        render={(props) => (
          <LazyRoute {...props} component={import(`./${router.component}`)}/>
        )}
      />
    ))}
  </React.Fragment>
);
