import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from 'mobx-react';
import {Modal, Button} from 'react-bootstrap';

@withTranslation()
@inject('store')
@observer
class AddAdCategoriesComponent extends Component {
  constructor(props){
    super(props);

    this.store = this.props.store.appStore;
    this.adStore = this.props.store.adStore;

    this.initialState = {
      history: [this.props.categories],
      index: 0,
      prevQuestionIsDisabled: true,
      nextQuestionIsDisabled: true,
    };

    this.state = this.initialState;
    this.selectCategory = this.selectCategory.bind(this);
    this.prevQuestionHandler = this.prevQuestionHandler.bind(this);
  }

  prevQuestionHandler(){
    this.setState((prevState) => {
      let history = prevState.history;
      history.splice(-1, 1);
      return {
        ...prevState,
        index: prevState.index - 1,
        history,
        prevQuestionIsDisabled: prevState.index <= 1
      }
    });
  }

  selectCategory(e, c){
    e.preventDefault();
    if (c.children.length > 0){
      this.setState((prevState) => {
        return {
          ...prevState,
          history: [...prevState.history, c.children],
          index: prevState.index + 1,
          prevQuestionIsDisabled: false
        }
      });
    } else {
      this.adStore.setAddAdCategory(c.id);
      this.adStore.setAddAdForms(c.forms);
      this.adStore.setAddAdCurrentShow(c.forms[0]);
    };
    return false;
  }

  render(){
    const {t} = this.props;

    return(
      <React.Fragment>
        <Modal.Body>
          <div className="h4">{t('modal.content.selectCategory')}</div>
          <div>
            {this.state.history[this.state.index].map((c, i) => (
              <div key={i} className="m-2"><a onClick={(e) => this.selectCategory(e, c)} href="#">{c[`title_${this.store.language}`]}</a></div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button size="sm" disabled={this.state.prevQuestionIsDisabled} variant="secondary" onClick={this.prevQuestionHandler}>
            {t("buttonLabel.addAdModal.prevQuestion")}
          </Button>
          <Button size="sm" disabled={this.state.nextQuestionIsDisabled} variant="primary" onClick={this.send}>
            {t("buttonLabel.addAdModal.nextQuestion")}
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

export default AddAdCategoriesComponent;
