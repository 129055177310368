import React from 'react';
import {Spinner} from 'react-bootstrap';

const getStyles = (height) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: height || '200px',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

export default class Loading extends React.Component {
  static defaultProps = {
    title: 'Loading...',
    height: null,
  };

  render(){
    const styles = getStyles(this.props.height);

    return (
      <div style={styles.container}>
        <div style={styles.content}>
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">{this.props.title}</span>
          </Spinner>
        </div>
      </div>
    );
  }
}
