import i18n from 'i18next';
import XHR from "i18next-xhr-backend";

import translationKy from './locales/ky.json';
import translationRu from './locales/ru.json';
import {DEBUG_MODE} from "./settings";

i18n.use(XHR).init({
  resources: {
    ky: { translations: translationKy },
    ru: { translations: translationRu }
  },
  fallbackLng: 'ru',
  debug: DEBUG_MODE,
  lng: window.localStorage.getItem('language') || 'ru',
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {wait: true}
});

export default i18n;
