import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHandshake,
  faPhoneAlt,
  faQuestionCircle,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {withTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

@withTranslation()
class AppFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
    }
  }

  render() {
    const {t} = this.props;

    return (
      <>
        <Modal centered show={this.state.setShow} onHide={() => this.setState({setShow: false})}>
          <Modal.Header closeButton>
            <Modal.Title><h5>Тех.поддержка SOM.KG</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a style={{display: 'block', marginBottom: 8}}
               href="https://api.whatsapp.com/send?phone=+996775600691&text=">
              <FontAwesomeIcon
                fixedWidth
                size="sm"
                icon={["fab", "whatsapp"]}
              />{" "}
              +996 775 600 691
            </a>
            <a href="tel:+996775600691">
              <FontAwesomeIcon fixedWidth size="sm" icon={faPhoneAlt}/> +996 775 600 691
            </a>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={() => this.setState({setShow: false})}>
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="App-footer">
          <Row>
            {/*<Col lg={4} xl={4} md={6} sm={6} xs={12} className="text-center mb-4">*/}
            {/*  <div>*/}
            {/*    <strong>{t("brand.description")}</strong>*/}
            {/*  </div>*/}
            {/*</Col>*/}
            <Col
              lg={4}
              xl={4}
              md={6}
              sm={6}
              xs={12}
              className="mb-4 d-flex flex-column"
            >
              <h5>Мы в соц.сетях</h5>
              <a target={'_blank'} href="https://www.facebook.com/somkg2018">
                <FontAwesomeIcon
                  fixedWidth
                  size="sm"
                  icon={["fab", "facebook"]}
                />{" "}
                Facebook страница
              </a>
              {/*<a target={'_blank'} href="https://www.facebook.com/groups/somkg">*/}
              {/*  <FontAwesomeIcon*/}
              {/*    fixedWidth*/}
              {/*    size="sm"*/}
              {/*    icon={["fab", "facebook"]}*/}
              {/*  />{" "}*/}
              {/*  Facebook группа*/}
              {/*</a>*/}
              <a target={'_blank'} href="https://instagram.com/somkg2018?igshid=6j3chxvxvhpy/">
                <FontAwesomeIcon
                  fixedWidth
                  size="sm"
                  icon={["fab", "instagram"]}
                />{" "}
                Instagram
              </a>
              {/*<a target={'_blank'} href="https://ok.ru/somkg">*/}
              {/*  <FontAwesomeIcon*/}
              {/*    fixedWidth*/}
              {/*    size="sm"*/}
              {/*    icon={["fab", "odnoklassniki"]}*/}
              {/*  />{" "}*/}
              {/*  Одноклассники группа*/}
              {/*</a>*/}
              <a target={'_blank'} href="https://ok.ru/profile/589225181753">
                <FontAwesomeIcon
                  fixedWidth
                  size="sm"
                  icon={["fab", "odnoklassniki"]}
                />{" "}
                Одноклассники
              </a>
            </Col>
            <Col
              lg={4}
              xl={4}
              md={6}
              sm={6}
              xs={12}
              className="mb-4 d-flex flex-column"
            >
              <h5>Контакты SOM.KG</h5>
              <a href={"#"} onClick={(e) => {
                e.preventDefault();
                this.setState({setShow: true});
              }}>
                <FontAwesomeIcon fixedWidth size="sm" icon={faPhoneAlt}/>{" "}
                Тех.поддержка SOM.KG
              </a>
              <a href="mailto:somkg2018@gmail.com">
                <FontAwesomeIcon fixedWidth size="sm" icon={faEnvelope}/>{" "}
                somkg2018@gmail.com
              </a>
            </Col>
            <Col
              lg={4}
              xl={4}
              md={6}
              sm={6}
              xs={12}
              className="mb-4"
              style={{display: "flex", flexDirection: "column"}}
            >
              {/*  <Link to="/tariffs"><FontAwesomeIcon fixedWidth size='sm' icon={faReceipt} /> {t('linkLabel.ourTariffs')}</Link>*/}
              {/*  <Link to="/contacts"><FontAwesomeIcon fixedWidth size='sm' icon={faPhoneAlt} /> {t('linkLabel.ourContacts')}</Link>*/}
              {/*  <Link to="/"><FontAwesomeIcon fixedWidth size='sm' icon={faBullhorn} /> {t('linkLabel.advertising')}</Link>*/}
              <Link to="/help">
                <FontAwesomeIcon fixedWidth size="sm" icon={faQuestionCircle}/>{" "}
                {t("linkLabel.help")}
              </Link>
              <Link to="/terms">
                <FontAwesomeIcon fixedWidth size="sm" icon={faHandshake}/>{" "}
                {t("linkLabel.terms")}
              </Link>
              <Link to="/about_us">
                <FontAwesomeIcon fixedWidth size="sm" icon={faBookmark}/>{" "}
                {t("О нас")}
              </Link>
            </Col>
          </Row>
          <div className={"d-flex justify-content-around"}>
            <div>
              Разработчик:{" "}
              <a href={"https://api.whatsapp.com/send?phone=+996777171171&text="}>
                Besoft
              </a>{' '}
              (<a href={'mailto:tabekg@gmail.com'}>tabekg@gmail.com</a>)
            </div>
            <div>&copy; Som.kg 2018 - {moment().year()}</div>
          </div>
        </div>
      </>
    );
  }
}

export default AppFooter;
