import React from 'react';
import Modal from "react-bootstrap/Modal";
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import PhoneRenderer from "../profile/PhoneRenderer";
import {Button} from "react-bootstrap";
import {filterPhoneNumbers, requester} from "../../utils";
import autobind from "autobind-decorator";

@withTranslation() @inject('store') @observer
class PhoneRequiredModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.store = this.props.store.appStore;

    this.state = {
      savingContacts: false,
      phones: [],
    };
  }

  componentDidMount() {
    this.setState({phones: (this.store.user && this.store.user.phones) || []});
  }

  @autobind
  async onSaveContacts() {
    if (this.state.savingContacts) return;

    for (let i = 0; i < 3; i++) {
      if (this.state.phones[i].phone.length !== 12 && this.state.phones[i].phone.length > 0) {
        this.store.showError(`Неверный формат номера телефона ${i + 1}`);
        i = 3;
        return;
      }
    }

    this.setState({savingContacts: true});
    try {
      const data = await requester.post(`/user/phones`, {
        phone_1: this.state.phones[0].phone,
        phone_2: this.state.phones[1].phone,
        phone_3: this.state.phones[2].phone,
        whatsapp_1: this.state.phones[0].whatsapp,
        whatsapp_2: this.state.phones[1].whatsapp,
        whatsapp_3: this.state.phones[2].whatsapp,
      });
      if (data.status === 'success') {
        this.store.showSuccess(this.props.t('toast.success.saved'));
        this.store.user.setPhones(filterPhoneNumbers(data.data));
      }
    } finally {
      this.setState({savingContacts: false});
    }
  }

  render() {
    const {t} = this.props;
    const empty_phones = this.state.phones.filter(p => p.exists).length === 0;

    return (
      <Modal centered
             show={!this.store.has_phone && !this.store.authenticating}
             onHide={() => false}>
        <Modal.Header>
          <Modal.Title as="h5">{t("modal.title.phoneNumberRequired")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.store.user && this.store.user.phones.map((phone, index) => (
            <PhoneRenderer
              hideConfirmButton
              phone={phone}
              t={t}
              onChange={p => {
                this.setState((prevState) => {
                  prevState.phones[index] = p;
                  return prevState;
                });
              }}
              disabled={this.state.savingContacts}
              code={index + 1}
            />
          ))}
          <Button onClick={this.onSaveContacts} disabled={this.state.savingContacts || empty_phones} size="sm"
                  className="float-right">{t('buttonLabel.save')}</Button>
        </Modal.Body>
      </Modal>
    );
  }
}

export default PhoneRequiredModalComponent;